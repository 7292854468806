.signature {
    display: flex;
    width: 100%;
    gap: 16px;
    &-sign-rol {
        display: none;
    }
    &-sign-placement {
        height: 100px;
        vertical-align: bottom;
    }
    &-in-editor {
        margin: 5px;
        padding: 5px;
        border: none;
        background: var(--shadow-background);
        width: 300px;
        height: 140px;
        display: inline-block;
    }
    &-container {
        flex: 1;
    }
    &-title {
        display: flex;
        width: 100%;
        &-label {
            font-size: 16px;
            line-height: 32px;
            display: flex;
            gap: 8px;
        }
    }
    &-text {
        padding-left: 40px;
        min-height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &-message {
        display: flex;
        align-items: center;
    }
    &-warning {
        color: var(--color-error);
    }
    &-signer {
        margin: 24px 0;
    }
    &-type {
        margin: 0 0 16px 3px;
        &-number {
            border: var(--border-default);
            border-radius: 20px;
            display: inline-block;
            font-size: 13px;
            margin: 0 8px 0 0;
            text-align: center;
            height: 20px;
            line-height: 20px;
            width: 20px;
        }
        &-name {
            font-size: 15px;
            font-weight: 500;
        }
    }
    &-data {
        overflow: hidden;
        &-left {
            width: 50%;
            float: left;
        }
        &-right {
            width: 50%;
            float: right;
        }
        &-user {
            margin: 0 0 12px 30px;
            .title {
                font-weight: 500;
                margin: 0 0 2px 0;
                font-size: 14px;
            }
            .subtitle {
                color: var(--color-text-secondary);
                font-size: 15px;
                font-weight: 300;
                margin-bottom: 16px;
            }
            .pending {
                color: var(--color-state-signing);
                font-weight: 400;
            }
        }
    }
    &-info {
        display: flex;
        flex-direction: column;
        font-size: 13px;
        gap: 16px;
        min-width: 25vw;
        &-block {
            label {
                margin: 0 16px 0 8px;
                color: var(--color-gray-700);
                vertical-align: 3px;
            }
            input {
                outline: none;
            }
            .title {
                margin-bottom: 12px;
                font-size: 16px;
                font-weight: 500;
                display: flex;
                gap: 8px;
                align-items: center;
            }
        }
        &-expiration {
            input[type="text"] {
                width: 30px;
                height: 20px;
                vertical-align: middle;
                border: var(--border-default);
            }
        }
    }
    &-list {
        min-height: 60px;
        overflow: hidden;
        display: block;
        &-item {
            padding: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            box-sizing: border-box;
            cursor: move;
        }
    }
    &-inline {
        margin-left: 32px;
    }
}

.cke_widget_wrapper_signature-in-editor {
    display: inline-block;
}

.group-change-sel {
    del.diffmod,
    del.diffdel {
        margin: 1px;
    }
    del.diffmod::before {
        content: "";
        top: 8px;
    }
    img.diffdel {
        border-left: solid var(--color-text-secondary);
        box-sizing: border-box;
        background-color: transparent !important;
    }
    img.diffins {
        border-left: solid var(--color-text-secondary);
        background-color: transparent !important;
        box-sizing: border-box;
    }
    .diffins,
    .diffdel,
    .diffmod,
    .mod {
        outline: 3px dotted var(--color-blue-600);
        margin: 0 4px;
    }

    del {
        text-decoration: line-through;
        background-color: var(--background-color-deleted);
        color: var(--color-delete);
        padding: 2px;
        select {
            background-color: var(--background-color-deleted) !important;
            padding: 2px;
        }
        input {
            background-color: var(--background-color-deleted) !important;
            padding: 2px;
        }
    }

    ins {
        text-decoration: none;
        background-color: var(--background-color-insert) !important;
        color: var(--color-success);
        padding: 2px;
        select {
            background-color: var(--background-color-insert) !important;
            color: var(--color-success);
            padding: 2px;
        }
        input {
            background-color: var(--background-color-insert) !important;
            color: var(--color-success);
            padding: 2px;
        }
    }
}
