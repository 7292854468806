.smart-forms .wk-document .FO #dTxT .pmG.cl,
.smart-forms .wk-document .FO #dTxT .pmG .cl,
.smart-forms .wk-document .FO #dTxT .opt .cl {
    display: none;
}

.smart-forms .wk-document .FO #dTxT .pmG.op,
.smart-forms .wk-document .FO #dTxT .pmG .op,
.smart-forms .wk-document .FO #dTxT .opt .op {
    display: block;
}

.smart-forms .wk-document .FO #dTxT .pmG .cB dt {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
}