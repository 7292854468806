.paginator {
    .mat-paginator-container {
        min-height: 51px;
        padding: 0 6px;

        @include media(XXS) {
            padding: 0;
        }

        .mat-paginator-range-label:not(:empty) {
            margin: 4px 24px 0 12px;
            font-size: 14px;
            text-align: right;

            @include media(XXS) {
                margin: 4px 4px 0 4px;
                font-size: 12px;
            }
        }

        .mat-paginator-range-label:empty {
            display: none;
        }
        .mat-paginator-icon {
            width: 24px;
        }

        .mat-form-field-infix {
            width: 64px;
        }
    }
}
