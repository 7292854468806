.main {
    padding: 16px;
    &.aditional-document {
        padding: 39px 0 100px 0;
    }
    &-close {
        float: right;
    }
    &-title {
        text-align: center;
        font-size: 22px;
        @include media(XS) {
            margin: 0 16px;
            line-height: 27px;
        }
    }
    &-subtitle {
        text-align: center;
        font-size: 19px;
        margin: 13px 0 48px 0;
        color: var(--color-text-secondary);
        @include media(XS) {
            margin: 13px 16px 48px 16px;
            line-height: 25px;
        }
    }
}

.ctbox-cards-group {
    align-items: flex-start;
    display: flex;
    gap: 32px;
    justify-content: center;
    flex-wrap: wrap;
    .ctbox-card {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-width: 260px;
        &-link {
            align-content: center;
            align-items: center;
            background-color: var(--color-white);
            background-position: center 32px;
            background-repeat: no-repeat;
            border: var(--border-default);
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            height: 240px;
            width: 260px;
            padding: 32px;
            @include media(XS) {
                flex-direction: row;
                justify-content: space-between;
                height: auto;
                padding: 16px;
            }
            &.cmc {
                &-bp,
                &-bc,
                &-aj {
                    align-items: flex-start;
                    justify-content: flex-start;
                    border-bottom: 4px solid var(--color-santander);
                    @include media(XS) {
                        align-items: center;
                        justify-content: space-between;
                    }
                }
            }
            &-title {
                color: var(--color-text);
                font-size: 19px;
                font-weight: 500;
                outline: none;
                text-decoration: none;
            }
            &-text {
                color: var(--color-text-secondary);
                font-size: 12px;
                min-height: 85%;
                outline: none;
                padding: 24px 0 0 0;
                text-decoration: none;
                @include media(XS) {
                    display: none;
                }
            }
            &-button {
                align-self: flex-end;
                display: flex;
            }
            &.from-tdc {
                &.documents {
                    background-image: url("~src/assets/images/contracts-from-template.svg");
                }
                &.templates {
                    background-image: url("~src/assets/images/templates-lld-model.svg");
                }
                &.clauses {
                    background-image: url("~src/assets/images/clauses-library-wk-model.svg");
                }
            }
            &.from-file {
                &.documents {
                    background-image: url("~src/assets/images/contracts-upload-document.svg");
                }
                &.templates {
                    background-image: url("~src/assets/images/templates-upload-document.svg");
                }
                &.clauses {
                    background-image: url("~src/assets/images/clauses-upload-document.svg");
                }
                &.cmc {
                    background-image: url("~src/assets/images/selection-upload-document.svg");
                }
            }
            &.from-new {
                &.documents {
                    background-image: url("~src/assets/images/contracts-create-document.svg");
                }
                &.templates {
                    background-image: url("~src/assets/images/templates-create-document.svg");
                }
                &.clauses {
                    background-image: url("~src/assets/images/clauses-library-create-document.svg");
                }
                &.cmc {
                    background-image: url("~src/assets/images/selection-create-document.svg");
                }
            }
            &:focus,
            &:hover {
                outline: none;
                text-decoration: none;
                box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1);
            }
            &:active {
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
            }
            &.help {
                height: 130px;
                width: 120px;
                background-size: 80px;
            }
        }
        &-text {
            color: var(--color-text-secondary);
            font-size: 15px;
            line-height: 20px;
            text-align: center;
        }
    }
}
