@use "@angular/material"as mat;

@mixin mat-list($theme) {
    .mat-list {
        &-base {
            &.versions {
                padding-top: 0;
                background-color: var(--tree-background-color);

                .mat-list-single-selected-option {
                    background: var(--color-white);

                    .mat-list-item-data {
                        color: var(--color-info);
                    }

                }

                .mat-list-item-content {
                    padding: 0 0 0 2px !important;
                    justify-content: space-between;
                }

                .mat-list-text {
                    padding: 0 !important;
                }

                .mat-list-item-data {
                    &.in-force {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                    }
                }

            }
        }

        &-option {
            border: 2px solid var(--color-white);
            height: 36px !important;
            font-size: 14px !important;

        }


    }
}