.read-only-clause {
    align-items    : stretch;
    background     : #a4021a12 !important;
    display        : flex;
    justify-content: space-between;
    line-height    : 24px;
    margin         : 8px;
    padding        : 8px 0 0 8px;
    position       : relative;
}


.read-only-clause::before {
    content     : '';
    width       : 0;
    height      : 0;
    border-style: solid;
    border-width: 0 0 16px 16px;
    border-color: transparent #a4021a #fff;
    right       : 0;
    bottom      : 0;
    position    : absolute;
}

.read-only-clause-icon {
    font-family            : Material Symbols Outlined;
    font-family            : var(--font-family-icons);
    font-variation-settings: "FILL"0, "wght"400, "GRAD"0, "opsz"48;
    background-color       : transparent;
    border                 : none;
    font-size              : 24px;
    cursor                 : pointer;
}

.read-only-clause-icon.delete {
    color: var(--color-delete);
}

.read-only-clause-icon.link {
    align-self : flex-end;
    color      : var(--color-info);
    font-size  : 1px;
    line-height: 16px;
    max-width  : 16px;
    max-height : 16px;
    z-index    : 1;
}


.read-only-clause-body {
    flex: 1;
}

.read-only-clause-buttons {
    display        : flex;
    flex-direction : column;
    justify-content: space-between;
    align-items    : stretch;
    align-content  : stretch;
}


.read-only-clause-body #tBody #dTxT,
.read-only-clause-body #tBody {
    margin: 0;
}

.html-viewer #tBody .dTxT[data-embebed-idd] {
    background: transparent !important;
    padding   : 0;
}


#tBody .dTxT[data-embebed-idd] {
    padding: 8px;
}

#tBody .dTxT[data-embebed-idd] #dHead {
    display: none;
}

#tBody .dTxT[data-embebed-idd] #dTxT {
    margin: 0;
}