.actions {
    align-content: center;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    min-height: $ctbox-actions-height;
    padding: 4px 24px 4px 24px;
    &-bar {
        display: flex;
        align-content: center;
        align-items: flex-start;
        justify-content: space-between;
        gap: 8px;
        width: 100%;
    }
    &-title {
        align-items: center;
        display: flex;
        width: 50%;
    }
    &-buttons {
        display: flex;
        flex: 1;
        gap: 8px;
        justify-content: flex-end;
    }
    &-filters {
        margin-bottom: 24px;
    }
    .save-container {
        display: flex;
    }
    .notification-to-user {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
    }
}
