@use "@angular/material"as mat;

@mixin mat-table($theme) {
    $tr-even-background-color: var(--shadow-background);
    $tr-active-background-color: var(--color-gray-200);
    $tr-selected-background-color: var(--color-orange-50);
    $tr-selected-hover-background-color: var(--color-orange-200);

    $tr-permission-background-color: var(--color-gray-200);
    $td-permission-border-color: var(--color-gray-400);
    $td-permission-icon-admin-color: var(--color-orange-600);
    $td-permission-icon-access-color: var(--color-blue-500);

    .mat-table {
        caption {
            display: none;
        }

        &.table-list {
            width: 100%;

            .icon {
                display: inline-block;
                font-size: 24px;
                font-style: normal;
                text-align: center;
                margin: 3px 0 0 0;
                width: 24px;
                height: 24px;
            }

            tr.mat-header-row {
                height: 40px;

                &.xl {
                    height: 56px;
                }
            }

            th.mat-header-cell {
                color: var(--color-text);
                border-bottom-color: var(--border-color-default);
                font-size: 14px;
                vertical-align: middle;
                line-height: 2.5em;
                padding-right: 16px;

                &.space-icon {
                    padding: 0 32px !important;
                }
            }

            .mat-row {
                &:nth-child(odd) {
                    background-color: var(--shadow-background);
                }
            }

            td.mat-cell {
                border-bottom: none;
                color: var(--color-text);
                cursor: default;
                font-size: 13px;
                line-height: 1.75em;
                vertical-align: middle;
            }
        }


        &.table-bg-white {
            box-sizing: border-box;
            max-width: 100%;
            min-width: 100%;
            width: 100%;

            tr.mat-row,
            tr.mat-footer-row,
            tr.mat-header-row {
                height: auto;
            }

            .mat-cell {
                vertical-align: middle;
                padding: 4px 8px 0 0;

                &-check {
                    width: 32px;
                    line-height: 32px;
                    text-align: center;
                    vertical-align: middle;
                }
            }

            .mat-row {
                &:nth-child(even) {
                    background-color: $tr-even-background-color;
                }

                &:focus,
                &:hover,
                &:active {
                    background-color: $tr-active-background-color;
                    transition: background-color 200ms ease-in-out;
                }

                &.selected {
                    background-color: $tr-selected-background-color;

                    &:focus,
                    &:hover,
                    &:active {
                        background-color: $tr-selected-hover-background-color;
                        transition: background-color 200ms ease-in-out;
                    }
                }
            }
        }

        &.table-permission {
            th.mat-header-cell {
                background-color: $tr-permission-background-color;
                border-left: 1px solid $td-permission-border-color;
                padding: 8px 24px;
                vertical-align: top;
                line-height: 2.5em;
                height: 88px;
            }

            td.mat-cell {
                border-left: 1px solid $td-permission-border-color;
                max-width: 50%;
                padding: 8px 24px;
            }

            .alert {
                padding-left: 16px;
            }
        }

        &.table-ctbox-border {
            box-shadow: 0 2px 6px 0 rgb(0 0 0 / 16%);
            border: var(--border-default);
        }

        &.table-legal-alert {
            th.mat-header-cell {
                color: var(--color-santander-dark);
                font-size: 1.2em;
                padding: 8px;
                text-align: center;

                .mat-sort-header-content {
                    padding: 8px 0;
                }
            }

            td.mat-cell {
                padding: 8px 16px;
            }

            .mat-column {

                &-DocumentName,
                &-RelatedLaw,
                &-ModifierLaw {
                    min-width: 200px;
                }
            }
        }
    }

    .mat-paginator-container {
        min-height: auto;
    }

    .mat-paginator-range-label {
        margin: 0;
    }
    
    .mat-paginator-page-size-select {
        margin: 0;
    }

    .mat-cell-flex {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .mat-cell-content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .mat-cell-text {
            font-size: 1em;
        }

        .mat-cell-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            line-height: 24px;
            border-radius: 50%;

            &.administrator {
                color: var(--color-white);
                background-color: var(--color-update);
            }
        }
    }

    .mat-sort-header-content {
        text-align: left;
        flex-direction: column;
        align-items: flex-start;
        white-space: nowrap;
    }

    .mat-column {
        &-name {
            max-width: 600px;
            width: 100%;
        }
        &-templateStatus {
            min-width: 150px;
        }

        &-creationTimeStamp,
        &-modificationTimeStamp {
            min-width: 160px;
        }
    }

    .mat-cell {

        &-ellipsis,
        .name,
        .link {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
