#tBody {
    line-height: 1.5em;
    margin     : 0 0 3em 0;
    padding    : 0;
    text-align : justify;
    height     : 100% !important;
    width      : 100%;
}

#tBody dt,
#tBody dd {
    line-height: inherit;
    font-weight: inherit;
}

#tBody em,
#tBody i {
    font-style: italic;
}

#tBody h1,
#tBody h2,
#tBody h3,
#tBody h4,
#tBody h5,
#tBody h6,
#tBody p {
    font-size  : inherit;
    line-height: inherit;
    margin     : 0;
    padding    : 0;
}

#tBody form {
    margin-bottom: 0;
}

#tBody table {
    width: 100%;
}

#tBody .vntp {
    vertical-align: top !important;
}

#tBody td {
    text-align    : left;
    vertical-align: middle;
    border-bottom : 1px solid var(--color-text-secondary);
    border-right  : 1px solid var(--color-text-secondary);
}

#tBody td p {
    margin: .5em 0;
}

#tBody textarea {
    padding: 16px;
}

#tBody #dHead #eFO,
#tBody #dHead .pFA {
    display: none;
}

#tBody #dHead h2 {
    text-align    : center;
    text-transform: uppercase;
    font-size     : 1.3em;
}

#tBody #dHead > h2 {
    display: none;
}

#tBody #dHead h3 {
    text-align    : center;
    text-transform: uppercase;
    font-size     : 1em;
}

#tBody #dHPlus,
#tBody .dHPlus {
    margin-top: 3em;
}

#tBody #dHPlus .idU {
    display: none;
}

#tBody #dTxT {
    margin-top: 32px;
}
/*
#tBody #dTxT .fAb {
    padding: 1.5em;
    font-size: 1em;
    text-align: justify;
    line-height: 1.6em;
    border: 1px;
    border-color: var(--color-red-800);
    border-width: 1px;
    border-style: solid;
} */

#tBody #dTxT .d1,
#tBody #dTxT .d2 {
    font-weight: 500;
    margin-top: 3em;
    margin-bottom: 1em;
}

#tBody #dTxT .d3,
#tBody #dTxT .d4,
#tBody #dTxT .d5 {
    font-weight: 400;
    margin-top: 2em;
    margin-bottom: 1em;
}

#tBody #dTxT>div>p,
#tBody #dTxT>p {
    margin-top: 0.5em;
}

#tBody #dTxT p.d1+.d2 {
    margin-top: 1em;
}

#tBody .ad,
#tBody .anrt {
    text-align: right;
}

#tBody .ac,
#tBody .ancr {
    text-align: center;
}

#tBody .aj,
#tBody .anjy {
    text-align: justify;
}

#tBody .ai {
    text-align: left;
}

#dTxT .iMg a.vI+img {
    pointer-events: none;
}

#dTxT .iMg a.vI {
    display: none;
}

#tBody.FO table.feno tr {
    background-color: inherit !important;
}

#tBody.FO #dTxT>div h2.tanexo {
    text-align: center;
    color: black;
    text-transform: uppercase;
}

#tBody .tPb {
    display: table;
}

#tBody .table-responsive {
    border       : none;
    width        : 100%;
    margin-bottom: 15px;
    margin-top   : 20px;
    overflow-x   : auto;
    overflow-y   : hidden;
}

#tBody .table-responsive table {
    margin: auto;
    width : auto;
}

#tBody .su {
    text-decoration        : underline;
    text-underline-position: under;
}


#tBody #cCx,
#tBody #cCx h2,
#tBody #cCx #cVe,
#tBody #cCx #MenC,
#tBody #cCx .fVe,
#tBody #cCx .fVe li {
    display: none;
}


#tBody.FO #cCn table {
    display        : table;
    margin         : auto;
    border-collapse: collapse;
    overflow-x     : auto;
    width          : auto;
    max-width      : 100%;
    text-align     : center;
}

#tBody.FO table td {
    display: table-cell;
    padding: 0.5em;
}

#tBody.FO .fesd thead,
#tBody.FO .fell thead,
#tBody.FO .tPb thead {
    background-color: var(--color-soft-shadow);
}

#tBody.FO thead tr {
    background-color: transparent !important;
}

#tBody.FO .fell {
    border: 1px solid var(--color-text-secondary);
}

#tBody.FO .fesd {
    border-left : 1px solid var(--color-text-secondary);
    border-right: 1px solid var(--color-text-secondary);
}

#tBody.FO .febt {
    border-bottom: 1px solid var(--color-text-secondary);
}

#tBody.FO .fetp {
    border-top: 1px solid var(--color-text-secondary);
}

#tBody.FO .fetb {
    border-top   : 1px solid var(--color-text-secondary);
    border-bottom: 1px solid var(--color-text-secondary);
}

#tBody.FO .feno {
    border: 0px;
}

#tBody.FO td {
    vertical-align: middle;
}

#tBody.FO .vntp {
    vertical-align: top !important;
}

#tBody.FO .vnbm {
    vertical-align: bottom !important;
}

#tBody.FO .rpno {
    border-bottom: 0px !important;
    border-top   : 0px !important;
}


#tBody.FO .rotate {
    writing-mode  : vertical-lr;
    transform     : rotate(180deg);
    height        : auto;
    vertical-align: middle;
}


#tBody.FO .rpno td {
    border-bottom: 0px !important;
    border-top   : 0px !important;
}

#tBody.FO .cpno {
    border-right: 0px !important;
    border-left : 0px !important;
}

#tBody.FO table.feno tr {
    background-color: inherit !important;
}

#tBody.FO .plusminusgroup-text {
    color      : var(--color-text-secondary);
    margin-left: 8px;
    margin-top : 4px;
    display    : inline-block;
}

#tBody.FO .dFiC #cCn .pFA {
    display: none;
}

#cVe>p:first-child:before {
    content  : "VERSIONES DISPONIBLES";
    font-size: 14px;
}

#cVe>p:first-child {
    font-size : 0px;
    max-height: 32px;
}

#tBody .sumarios .dSF .h4 {
    padding-left: 0;
}

#tBody .sumarios .dSF .h4:before {
    display: none;
}

#tBody .sumarios .dSF .h4+dd {
    padding-left: 0;
}

#tBody #dCm {
    color          : inherit;
    display        : flex;
    font-size      : 1em;
    gap            : 0.5em;
    justify-content: flex-end;
    margin          : 1em;
    text-decoration: none;
}

#tBody #dCm+* {
    margin-top: 0;
}

#tBody #dCm:after {
    content    : "";
    font-family: var(--font-family-icons);
    font-size  : 1.2em;
}

#tBody #dCm.dCmO:after {
    content: "\e909";
}

#tBody #dCm.dCmC:after {
    content: "\e146";
}

#tBody #MenC {
    min-height: 90%;
    color     : var(--color-text-secondary);
    padding   : 0px 19.2px 32px 19.2px;
}

#tbody ul li {
    list-style-type: disc;
    padding-left: 24px;
}

#tbody ol li {
    list-style-type: decimal;
    padding-left: 24px;
}

#tBody .lDR {
    margin-left : auto;
    margin-right: auto;
    border      : 1px solid var(--color-primary);
    display     : table;
    margin      : 0.8em auto;
    padding     : 0 0.62em;
    line-height : 32px;
    height      : 32px;
    color       : var(--color-primary);
}

#tBody .lDR:before {
    content     : "\e865";
    font-family : var(--font-family-icons);
    margin-right: 8px;
    font-size   : 20px;
}

#tBody .lDR:after {
    clear  : both;
    display: table;
    content: "";
}

#tBody .lDR img {
    display: none;
}

#tBody *+.cDIn,
#tBody *+.cEsD,
#tBody *+.cCaD,
#tBody *+.cFiD {
    margin-left: 1.3em;
    margin-top : 0;
}

#tBody *+#cabecera,
#tBody *+#ecli,
#tBody *+#res-ant-y-sent-fallo-f,
#tBody *+#sDa {
    margin-top: 0.5em;
}

#tBody img+.cDIn,
#tBody img+.cEsD,
#tBody img+.cCaD,
#tBody img+.cFiD {
    margin-left: 0;
}

#tBody cite {
    margin-top: 0.5em;
    display   : block;
}

#tBody i {
    font-style: italic;
}

#tBody a:hover em,
#tBody a:link,
#tBody a:active,
#tBody a:focus {
    color: var(--color-info);
}

#tBody a:visited {
    color: var(--color-info);
}

#tBody a.cBL:before,
#tBody a.cCS:before,
#tBody a.cDT:before,
#tBody a.cFO:before,
#tBody a.cIF:before,
#tBody a.cJU:before,
#tBody a.cLE:before,
#tBody a.cPR:before,
#tBody a.cEX:before,
#tBody a.cAN:before,
#tBody a.cPT:before,
#tBody a.iBL:before,
#tBody a.iCS:before,
#tBody a.iDT:before,
#tBody a.iFO:before,
#tBody a.iIF:before,
#tBody a.iJU:before,
#tBody a.iLE:before,
#tBody a.iPR:before,
#tBody a.iEX:before,
#tBody a.iEN:before,
#tBody a.iAN:before,
#tBody a.iPT:before,
#tBody a.iPD:before {
    content    : "";
    height     : 18px;
    width      : 22px;
    margin     : 4px;
    color      : var(--color-info);
    font-family: var(--font-family-icons);
    overflow   : hidden;
    min-width  : 22px;
    max-height : 18px;
}


#tBody a.cBL img,
#tBody a.cCS img,
#tBody a.cDT img,
#tBody a.cFO img,
#tBody a.cIF img,
#tBody a.cJU img,
#tBody a.cLE img,
#tBody a.cPR img,
#tBody a.cEX img,
#tBody a.cAN img,
#tBody a.cPT img,
#tBody a.iBL img,
#tBody a.iCS img,
#tBody a.iDT img,
#tBody a.iFO img,
#tBody a.iIF img,
#tBody a.iJU img,
#tBody a.iLE img,
#tBody a.iPR img,
#tBody a.iEX img,
#tBody a.iEN img,
#tBody a.iAN img,
#tBody a.iPT img,
#tBody a.iPD img {
    display: none;
}

#tBody a.cBL em,
#tBody a.cCS em,
#tBody a.cDT em,
#tBody a.cFO em,
#tBody a.cIF em,
#tBody a.cJU em,
#tBody a.cLE em,
#tBody a.cPR em,
#tBody a.cEX em,
#tBody a.cAN em,
#tBody a.cPT em,
#tBody a.iBL em,
#tBody a.iCS em,
#tBody a.iDT em,
#tBody a.iFO em,
#tBody a.iIF em,
#tBody a.iJU em,
#tBody a.iLE em,
#tBody a.iPR em,
#tBody a.iEX em,
#tBody a.iEN em,
#tBody a.iAN em,
#tBody a.iPT em,
#tBody a.iPD em {
    display: table;
}

#tBody.cDT:before,
#tBody.iDT:before {
    content         : "\e972";
    background-image: none;
    font-family     : var(--font-family-icons);
}

#tBody.cCS:before,
#tBody.iCS:before {
    content         : "\e902";
    background-image: none;
    font-family     : var(--font-family-icons);
}

#tBody.cJU:before,
#tBody.iJU:before {
    content         : "\e901";
    font-family     : var(--font-family-icons);
    background-image: none;
    font-size       : 34px;
}

#tBody.cEX:before,
#tBody.iEX:before {
    content         : "\e903";
    font-family     : var(--font-family-icons);
    background-image: none;
}

#tBody.cIF:before,
#tBody.iIF:before {
    content         : "\e905";
    font-family     : var(--font-family-icons);
    background-image: none;
}

#tBody.cPR:before,
#tBody.iPR:before {
    content         : "\e900";
    font-family     : var(--font-family-icons);
    background-image: none;
}

#tBody.cFO:before,
#tBody.iFO:before {
    content         : "\e835";
    background-image: none;
    font-family     : var(--font-family-icons);
}

#tBody.iAN:before {
    content         : "\e90c";
    background-image: none;
}

#tBody.iEN:before {
    content         : "\e903";
    font-family     : var(--font-family-icons);
    background-image: none;
}

#tBody.iLE:before {
    content         : "\e9fe";
    background-image: none;
    font-family     : var(--font-family-icons);
}

#tBody.iBL:before {
    content         : "\e853";
    background-image: none;
    font-family     : var(--font-family-icons);
}

#tBody.cBL:before {
    display: none;
}

#tBody .iN {
    font-style: italic;
}

#tBody ul, #tBody ol {
    padding-left: 1em;
}

#tBody li {
    margin-top: 0.5em;
}

#tBody ul li:has( > b ) {
    list-style: none;
}

#tBody select {
    max-width: 100%;
}

#tBody [data-type]:first-child+* {
    margin-top: 0;
}

#tBody .a {
    text-align: left;
    display   : inline-block;
}

#tBody .a em {
    margin-right: 0.5em;
}

#tBody .a i {
    display: inline-block;
}

#tBody .a i:after {
    content: "";
    clear  : both;
    display: table;
}

#tBody .a a.cBL,
#tBody .a a.cCS,
#tBody .a a.cDT,
#tBody .a a.cFO,
#tBody .a a.cIF,
#tBody .a a.cJU,
#tBody .a a.cLE,
#tBody .a a.cPR,
#tBody .a a.cEX,
#tBody .a a.cAN,
#tBody .a a.cPT,
#tBody .a a.iBL,
#tBody .a a.iCS,
#tBody .a a.iDT,
#tBody .a a.iFO,
#tBody .a a.iIF,
#tBody .a a.iJU,
#tBody .a a.iLE,
#tBody .a a.iPR,
#tBody .a a.iEX,
#tBody .a a.iEN,
#tBody .a a.iAN,
#tBody .a a.iPT,
#tBody .a a.iPD {
    display: inline-block;
}

#tBody .cn {
    text-align    : center;
    text-transform: uppercase;
}

#tBody .ccn {
    margin-top      : 0.5em;
    background-color: var(--shadow-background);
    padding         : 16px;
}

#tBody .ccn a.cBL,
#tBody .ccn a.cCS,
#tBody .ccn a.cDT,
#tBody .ccn a.cFO,
#tBody .ccn a.cIF,
#tBody .ccn a.cJU,
#tBody .ccn a.cLE,
#tBody .ccn a.cPR,
#tBody .ccn a.cEX,
#tBody .ccn a.cAN,
#tBody .ccn a.cPT,
#tBody .ccn a.iBL,
#tBody .ccn a.iCS,
#tBody .ccn a.iDT,
#tBody .ccn a.iFO,
#tBody .ccn a.iIF,
#tBody .ccn a.iJU,
#tBody .ccn a.iLE,
#tBody .ccn a.iPR,
#tBody .ccn a.iEX,
#tBody .ccn a.iEN,
#tBody .ccn a.iAN,
#tBody .ccn a.iPT,
#tBody .ccn a.iPD {
    display: inline-block;
}

#tBody .ccn a.cBL:before,
#tBody .ccn a.cCS:before,
#tBody .ccn a.cDT:before,
#tBody .ccn a.cFO:before,
#tBody .ccn a.cIF:before,
#tBody .ccn a.cJU:before,
#tBody .ccn a.cLE:before,
#tBody .ccn a.cPR:before,
#tBody .ccn a.cEX:before,
#tBody .ccn a.cAN:before,
#tBody .ccn a.cPT:before,
#tBody .ccn a.iBL:before,
#tBody .ccn a.iCS:before,
#tBody .ccn a.iDT:before,
#tBody .ccn a.iFO:before,
#tBody .ccn a.iIF:before,
#tBody .ccn a.iJU:before,
#tBody .ccn a.iLE:before,
#tBody .ccn a.iPR:before,
#tBody .ccn a.iEX:before,
#tBody .ccn a.iEN:before,
#tBody .ccn a.iAN:before,
#tBody .ccn a.iPT:before,
#tBody .ccn a.iPD:before {
    display: inline-block;
}

#tBody .ccn em.aj {
    display    : block;
    line-height: 1.9em;
}

#tBody .ccnOff {
    display: none;
}

#tBody .cbox-convenio {
    border : 1px solid var(--color-info);
    padding: 16px;
}

#tBody .cbox-convenio em {
    margin-right: 0.8em;
}

#tBody .cDIn {
    line-height : 32px;
    margin-right: 16px;
}

#tBody .cDIn em {
    display: inline-block;
    color  : var(--color-text-secondary);
}

#tBody .cDIn:before {
    color       : var(--color-info);
    content     : "\e901";
    font-family : var(--font-family-icons);
    font-size   : 32px;
    margin-top  : -4px;
    width       : 32px;
    height      : 32px;
    margin-right: 8px;
}

#tBody .cDIn:after {
    content: "";
    clear  : both;
    display: table;
}

#tBody .cCaR {
    line-height : 32px;
    clear       : inherit;
    margin-right: 16px;
}

#tBody .cCaR em {
    display: inline-block;
    color  : var(--color-text-secondary);
}

#tBody .cCaR:before {
    color       : var(--color-info);
    content     : "\e901";
    font-family : var(--font-family-icons);
    font-size   : 32px;
    margin-top  : -4px;
    width       : 32px;
    height      : 32px;
    margin-right: 8px;
}

#tBody .cCaR:after {
    content: "";
    clear  : both;
    display: table;
}

#tBody .cEsD,
#tBody .cFiD {
    line-height : 32px;
    clear       : inherit;
    margin-right: 16px;
}

#tBody .cEsD em,
#tBody .cFiD em {
    display: inline-block;
    color  : var(--color-text-secondary);
    ;
}

#tBody .cEsD:before,
#tBody .cFiD:before {
    color       : var(--color-info);
    content     : "\e901";
    font-family : var(--font-family-icons);
    font-size   : 32px;
    margin-top  : -4px;
    width       : 32px;
    height      : 32px;
    margin-right: 8px;
}

#tBody .cEsD:after,
#tBody .cFiD:after {
    content: "";
    clear  : both;
    display: table;
}

#tBody .cCaD {
    line-height : 32px;
    clear       : inherit;
    margin-right: 16px;
}

#tBody .cCaD em {
    display: inline-block;
    color  : var(--color-text-secondary);
    ;
}

#tBody .cCaD:before {
    color       : var(--color-info);
    content     : "\e901";
    font-family : var(--font-family-icons);
    font-size   : 32px;
    margin-top  : -4px;
    width       : 32px;
    height      : 32px;
    margin-right: 8px;
}

#tBody .cCaD:after {
    content: "";
    clear  : both;
    display: table;
}

#tBody .cSFi {
    line-height : 32px;
    clear       : inherit;
    margin-right: 16px;
}

#tBody .cSFi em {
    display: inline-block;
    color  : var(--color-text-secondary);
    ;
}

#tBody .cSFi:before {
    color       : var(--color-info);
    content     : "\e901";
    font-family : var(--font-family-icons);
    font-size   : 32px;
    margin-top  : -4px;
    width       : 32px;
    height      : 32px;
    margin-right: 8px;
}

#tBody .cSFi:after {
    content: "";
    clear  : both;
    display: table;
}

#tBody .cl {
    display: none;
}

#tBody .cPlus {
    padding         : 1.3em;
    background-color: var(--shadow-background);
}

#tBody .cPlus .ccn {
    padding   : 0;
    border    : 0;
    margin-top: 0;
}

#tBody .dAb {
    border : 1px solid var(--color-info);
    padding: 1.3em;
}

#tBody .der {
    border: 1px solid var(--color-primary);
    color : var(--color-primary);
}

#tBody .dCo dt {
    cursor: pointer;
}

#tBody .dCo dt dfn {
    display: none;
}

#tBody .dCo dt:before {
    content     : "\e84c";
    font-family : var(--font-family-icons);
    margin-right: 8px;
}

#tBody .dCo dt:after {
    content: "Mostrar comentario";
}

#tBody .dCo dt.dop:before {
    content: "\e84f";
}

#tBody .dCo dt.dop:after {
    content: "Ocultar comentario";
}

#tBody .dCo .op {
    width: 100%;
}

#tBody .dFiC {
    min-height: auto !important;
}

#tBody .dE {
    background-color: var(--shadow-background);
    display         : initial;
}

#tBody .da a em {
    display: inline-block;
}

#tBody .da+.dFr {
    margin-top: 3em;
}

#tBody .da:after {
    content: "";
    clear  : both;
    display: table;
}

#tBody .di {
    line-height: 2.29em;
}

#tBody .di dd {
    margin-top: 0;
}

#tBody .di dl {
    margin-top: 0;
}

#tBody .di dt {
    clear       : both;
    margin-top  : 0;
    margin-right: 0.8em;
}

#tBody .di dt.Tit {
    margin-bottom: 0.8em;
    float        : none;
}

#tBody .di b {
    font-family: inherit;
}

#tBody .dSF {
    height: 100%;
}

#tBody .dSF .h3 {
    display: none !important;
}

#tBody .dSF .h3+* {
    margin-top : 0;
    padding-top: 0;
}

#tBody .dSF li {
    padding-left: 16px;
}

#tBody .dSF .h4 {
    position    : relative;
    line-height : 16px;
    padding-left: 24px;
}

#tBody .dSF .h4.dcl {
    cursor: pointer;
}

#tBody .dSF .h4.dcl:before {
    content: "\e83d";
}

#tBody .dSF .h4.dcl+dd {
    display: none;
}

#tBody .dSF .h4.dop {
    cursor: pointer;
}

#tBody .dSF .h4:before {
    content    : "\e846";
    font-family: var(--font-family-icons);
    position   : absolute;
    left       : 0;
    top        : 0;
    height     : 16px;
    width      : 16px;
    font-size  : 16px;
    text-align : center;
}

#tBody .dSF .h4+dd {
    padding-left: 24px;
}

#tBody .dSF .h4+dd ul {
    padding-left: 0;
}

#tBody .sDc {
    padding-left: 22px;
}


#tBody .dSM+.dSM {
    border-top : 1px solid var(--color-text-secondary);
    padding-top: 16px;
    margin-top : 0.5em;
}

#tBody .feno tr:nth-child(odd) {
    background-color: var(--color-white);
}

#tBody .fAb {
    padding: 1.3em;
    border : 1px solid var(--color-info);
}

#tBody .fAb {
    padding: 1.3em;
    border : 1px solid var(--color-info);
}

#tBody .fCo {
    border : 1px solid var(--color-info);
    padding: 16px;
}

#tBody .lb {
    color: var(--color-info);
}

#tBody .li {
    padding-left: 0.8em;
    display     : block;
}

#tBody .jCon:before {
    color       : var(--color-primary);
    content     : "\e81b";
    font-family : var(--font-family-icons);
    margin-right: 8px;
    width       : 24px;
    font-size   : 20px;
    text-align  : center;
}

#tBody .jCon:after {
    content: "";
    clear  : both;
    display: table;
}

#tBody .jDa {
    margin-bottom: 15px;
}

#tBody .jPte {
    display    : block;
    margin-left: 39px;
}

#tBody .jVA img {
    display: none;
}

#tBody .jVA:after {
    content: "";
    clear  : both;
    display: table;
}

#tBody .jFav:before {
    color       : var(--color-success);
    content     : "\e81a";
    font-family : var(--font-family-icons);
    margin-right: 8px;
    width       : 24px;
    font-size   : 20px;
    text-align  : center;
}

#tBody .jFav:after {
    content: "";
    clear  : both;
    display: table;
}

#tBody .NavigationTDC {
    margin-top: 0;
}

#tBody .nCl {
    display: none;
}


#tBody .nOp {
    display: none;
}

#tBody .negrita {
    font-weight: 500;
}

#tBody .nh {
    display : inline-block;
    position: relative;
    top     : -6px;

}

#tBody .nh dfn {
    display: none;
}

#tBody .nh:after {
    color      : var(--color-primary);
    content    : "\e88e";
    font-family: var(--font-family-icons);
    font-size  : 16px;

}

#tBody .nh:hover {
    text-decoration: none;
}

#tBody .nh+cite {
    display: none;
}

#tBody .nh:hover+cite {
    background-color: var(--color-info);
    box-shadow      : var(--box-shadow);
    color           : var(--color-white);
    display         : inline-block;
    font-size       : 12px;
    padding         : 4px;
    position        : absolute;
}

#tBody .pR {
    background-color: var(--shadow-background);
}

#tBody .pOf em {
    margin-right: 8px;
}

#tBody .Tit {
    color      : var(--color-info);
    line-height: 1.4em;
}


#tBody.FO #dTxT .tanexo~.opt,
#tBody.FO #dTxT .sign>.opt {
    padding: 16px 16px 16px 32px;
}

#tBody #dTxT .opt+p {
    margin-top: 35px;
}

#tBody .rSelect .orSelect:first-of-type:before {
    color: var(--color-info);
}

#tBody .orSelect {
    color: var(--background-color-disabled);
}

#tBody .orSelect.sel {
    color: var(--color-black);
}

#tBody.FO #dTxT>.opt,
#tBody.FO #dTxT .tanexo~.opt,
#tBody.FO #dTxT .sign>.opt {
    border: 1.5px solid var(--color-info);
}

#tBody .rGroup .rBox span.opt select {
    margin-right: 5px;
    margin-left : 5px;
}

#tBody .rGroup.sel .rBox:not(.sel) {
    border : var(--border-default);
    opacity: .75;
}

#tBody .rBox,
#tBody .opt {
    background-color: initial;
    padding         : 16px 16px 16px 32px;
    line-height     : 1.5em;
    margin          : 16px 0;
}

#tBody span.opt,
#tBody .opt .opt {
    padding: 0;
}



#tBody.FO #dTxT .tanexo~.opt,
#tBody.FO #dTxT .sign>.opt {
    padding: 16px 16px 16px 32px;
}

#tBody .op {
    display: inline-block;
}

#tBody .opt a[data-cke-pa-onclick*="supp("],
#tBody .opt a[data-cke-pa-onclick*="add("],
#tBody .opt a[onclick*="add("],
#tBody .opt a[onclick*="supp("] {
    display         : inline-block;
    background-color: var(--color-info);
    color           : var(--color-white);
    margin          : 0 4px;
    font-size       : 20px;
    line-height     : 20px;
    height          : 20px;
    width           : 20px;
    text-align      : center;
    cursor          : pointer;
}

#tBody .opt a[data-cke-pa-onclick*="supp("]:focus #tBody .opt a[data-cke-pa-onclick*="add("]:hover,
#tBody .opt a[data-cke-pa-onclick*="supp("]:hover,
#tBody .opt a[data-cke-pa-onclick*="add("]:focus,
#tBody .opt a[onclick*="add("]:focus,
#tBody .opt a[onclick*="supp("]:focus,
#tBody .opt a[onclick*="add("]:hover,
#tBody .opt a[onclick*="supp("]:hover {
    background-color: var(--color-error);
    text-decoration : none;
    color           : var(--color-white);
}

#tBody .opt .plusminusgroup-text,
#tBody .rGroup .radiogroup-text,
#tBody .checkbox-text,
#tBody .checkbox-text::after {
    color: var(--color-info);
}

#tBody .rBox:first-child {
    border  : var(--border-default);
    padding : 16px 16px 16px 32px;
    position: relative;
}

#tBody .rBox:first-child:first-of-type {
    margin-top: 32px;
}

/* **************************************************** */
#tBody .tPb {
    margin         : auto;
    border-collapse: collapse;
    overflow-x     : auto;
    display        : block;
    width          : fit-content;
    max-width      : 100%;
    margin-top     : 5px;
}

#tBody .tPb .ac {
    display    : table-cell;
    font-family: inherit;
    text-align : center;
}

#tBody .vIm {
    width     : 35px;
    height    : 35px;
    margin-top: -7px;
}

#tBody #dTxTm *+p {
    margin-top : 0;
    padding-top: 0.5em;
}

#tBody #dTxTm a.cBL+.ccn,
#tBody #dTxTm a.cCS+.ccn,
#tBody #dTxTm a.cDT+.ccn,
#tBody #dTxTm a.cFO+.ccn,
#tBody #dTxTm a.cIF+.ccn,
#tBody #dTxTm a.cJU+.ccn,
#tBody #dTxTm a.cLE+.ccn,
#tBody #dTxTm a.cPR+.ccn,
#tBody #dTxTm a.cEX+.ccn,
#tBody #dTxTm a.cAN+.ccn,
#tBody #dTxTm a.cPT+.ccn,
#tBody #dTxTm a.iBL+.ccn,
#tBody #dTxTm a.iCS+.ccn,
#tBody #dTxTm a.iDT+.ccn,
#tBody #dTxTm a.iFO+.ccn,
#tBody #dTxTm a.iIF+.ccn,
#tBody #dTxTm a.iJU+.ccn,
#tBody #dTxTm a.iLE+.ccn,
#tBody #dTxTm a.iPR+.ccn,
#tBody #dTxTm a.iEX+.ccn,
#tBody #dTxTm a.iEN+.ccn,
#tBody #dTxTm a.iAN+.ccn,
#tBody #dTxTm a.iPT+.ccn,
#tBody #dTxTm a.iPD+.ccn {
    margin-top   : 0;
    margin-bottom: 0.5em;
    top          : 0.5em;
}

#tBody #dTxTm .der {
    color: inherit;
}

#tBody #relCont {
    position   : absolute;
    width      : 32px;
    height     : 32px;
    line-height: 48px;
    right      : 0;
    top        : 80px;
}

#tBody #relCont a {
    display    : block;
    line-height: 0;
}

#tBody #relCont img {
    height: 100%;
    width : 100%;
}

#tBody #relCont:after {
    width      : 35px;
    margin-top : -7px;
    content    : "\ea01";
    font-family: var(--font-family-icons);
    font-size  : 35px;
    color      : var(--color-info);
}

#tBody #relContDocAnexo {
    position   : absolute;
    width      : 48px;
    height     : 48px;
    line-height: 48px;
    right      : 0;
    top        : 80px;
}

#tBody #relContDocAnexo a {
    display: block;
}

#tBody #relContDocAnexo img {
    height: 100%;
    width : 100%;
}

#tBody #res-ant-y-sent-fallo-f .h3 {
    display: none !important;
}

#tBody #res-ant-y-sent-fallo-f p {
    font-style  : italic;
    padding-left: 16px;
}

#tBody #cabecera dt {
    display: none !important;
}

#tBody #dUrlBol {
    text-align: center;
}

#tBody #dNoT {
    font-size: 12px;
}

#tBody #dNoT dl {
    display: flex;
}

#tBody a.nf {
    color : var(--color-info);
    cursor: pointer;
}

#tBody #voces li {
    display     : block;
    position    : relative;
    padding-left: 24px;
    color       : inherit;
    cursor      : inherit;
}

#tBody #voces li:before {
    position   : absolute;
    left       : 0;
    top        : 0;
    content    : "\e82b";
    font-family: var(--font-family-icons);
    display    : block;
    height     : 16px;
    width      : 16px;
    font-size  : 16px;
    text-align : center;
    line-height: 16px;
}

#tBody #voces li+li {
    margin-top: 0.5em;
}

#tBody #voces li.op {
    cursor: pointer;
}

#tBody #voces li.op:before {
    content: "\e83d";
}

#tBody #voces li.op.iop:before {
    content: "\e846";
}

#tBody #voces li:not(.op) {
    cursor: default;
}

#tBody #voces li ul {
    margin-top  : 0.5em;
    clear       : both;
    display     : table;
    width       : 100%;
    padding-left: 0;
}

#tBody #voces .cl {
    display: none;
}

#tBody #ISIS,
#tBody #ISIN {
    width        : 100%;
    margin       : 16px 4.8px 0 0;
    position     : relative;
    padding-top  : 24px;
    min-height   : 100%;
    padding-right: 14px;
    border-top   : 1px solid;
    border-right : 1px solid;
    border-color : #c2c3c5;
}

#tBody #ISIS li,
#tBody #ISIN li {
    position     : relative;
    text-align   : left;
    margin-bottom: 6.4px;
}

#tBody #ISIS li.active>a:after,
#tBody #ISIN li.active>a:after {
    background-color: var(--color-info);
}

#tBody #ISIS li a.isisLink,
#tBody #ISIN li a.isisLink {
    background-color: var(--color-info);
    color           : white;
}

#tBody #ISIS li a.last span.last:after,
#tBody #ISIN li a.last span.last:after {
    content         : " ";
    position        : absolute;
    right           : -22px;
    width           : 15px;
    height          : 100%;
    background-color: var(--shadow-background);
    left            : inherit;
    top             : 0;
}

#tBody #ISIS li a:after,
#tBody #ISIN li a:after {
    content         : " ";
    position        : absolute;
    right           : -22px;
    border          : 1px solid var(--color-primary);
    width           : 15px;
    height          : 15px;
    border-radius   : 50%;
    background-color: white;
    left            : inherit;
    top             : 0;
}

#tBody #ISIS li+li,
#tBody #ISIN li+li {
    margin-top: 8px;
}

#tBody #ISIS a,
#tBody #ISIN a {
    display     : block;
    position    : relative;
    padding-left: 24px;
    color       : inherit;
}

#tBody #ISIS a:before,
#tBody #ISIN a:before {
    position   : absolute;
    left       : 0;
    top        : 0;
    content    : "\e82b";
    font-family: var(--font-family-icons);
    display    : block;
    height     : 16px;
    width      : 16px;
    font-size  : 16px;
    text-align : center;
    line-height: 16px;
}

#tBody #ISIS ul,
#tBody #ISIN ul {
    padding-left: 24px;
    margin-top  : 0.5em;
}

#tBody #ISIS .op>a:before,
#tBody #ISIN .op>a:before {
    content: "\e83d";
}

#tBody #ISIS .op.iop>a:before,
#tBody #ISIN .op.iop>a:before {
    content: "\e846";
}

#tBody #ISIS .cl,
#tBody #ISIN .cl {
    display: none;
}

#tBody #liDocISIS {
    display: none;
}

#tBody #liDocISIS:before {
    font-family: var(--font-family-icons);
    display    : block;
    height     : 32px;
    width      : 16px;
    font-size  : 16px;
    text-align : center;
    line-height: 32px;
    margin-left: 8px;
}

#tBody #liDocISIS.ExISISc:before {
    content: "\e84c";
}

#tBody #liDocISIS.ExISISo:before {
    content: "\e84f";
}

#tBody #prevUlDocISIS {
    margin-left: 19px;
    margin-top : 15px;
}

#tBody #prevLiDocISIS {
    color: var(--color-text-secondary);
}

#tBody #prevLiDocISIS:before {
    font-family : var(--font-family-icons);
    height      : 32px;
    width       : 16px;
    font-size   : 16px;
    text-align  : center;
    line-height : 17px;
    margin-right: 60px;
}

#tBody #prevLiDocISIS.ExISISc:before {
    content: "\e84c";
}

#tBody #prevLiDocISIS.ExISISo:before {
    content: "\e84f";
}

#tBody #prevLiDocISIS:hover {
    cursor: pointer;
}

#tBody #ISIS {
    padding-top: 15px;
}

#tBody #ISIS .iop>a:before,
#tBody #ISIS .op>a:before {
    content: "\e846";
}

#tBody #ISIS .icl>a:before {
    content: "\e83d";
}

#tBody #btc {
    margin-top: -40px;
}

#tBody #btc>* {
    line-height     : 16px;
    background-color: var(--color-info);
    padding         : 4px;
    font-size       : 12px;
    color           : var(--color-white);
    box-shadow      : var(--box-shadow);
}

#tBody .orSelect .rBox {
    margin-left : 0px;
    position    : relative;
    margin-right: 5px;
}


#tBody .rSelect .irBox {
    position    : relative;
    top         : 3px;
    margin-right: 6px;
    margin-left : 5px;
}

#tBody .rSelect {
    position: relative;
    display : block;
}

#tBody .rSelect .orSelect:first-of-type:before {
    content   : "Selecciona una u otra opci\00F3 n";
    position  : relative;
    display   : block;
    margin-top: 10px;
    color     : #a5a5a5;
}

#tBody .rBox input[type="radio"]+.rhDesc {
    margin-top : -5px !important;
    margin-left: 7px;
    font-style : italic;
    width: 80%;
}

#tBody .opt.xsel,
#tBody .sel .orSelect.sel,
#tBody .opt.selected.sel,
#tBody .rBox.sel,
#tBody .opt.sel {
    background-color: transparent;
}

#tBody .rBox:not(.sel):not(.xsel) {
    background-color: #ededed;
    font-style : italic;
    border : 1.5px solid var(--color-info);
}

#tBody .rBox.sel {
    border : 1.5px solid var(--color-info);
    opacity: .75 !important;
    font-style : italic;
}


#tBody p span.opt.sel {
    color: var(--color-black);
}

#tBody span.opt input.block {
    display   : block;
    position  : absolute;
    margin-top: 10px !important;
    z-index   : 10;
}

#tBody input.block~.rSelect .orSelect:first-of-type:before {
    margin-left: 24px;
}

#tBody .CS .pRt .cTit,
#tBody .CS .pC .cTit,
#tBody .CS .jEn .cTit {
    display    : block;
    text-align : center;
    font-weight: 500;
    margin-top : 25px;
}

#tBody .da .pEx {
    display: none;
}


#tBody [type="text"],
#tBody [type="password"],
#tBody textarea,
#tBody select,
#tBody input[type="checkbox"] {
    border          : var(--border-default);
    background-color: var(--background-color-input);
    height          : auto;
    margin-bottom   : 2px;
}


#tBody [type="text"],
#tBody [type="password"],
#tBody select {
    width : auto;
    height: 27px;
}

#tBody input.iMin {
    width: 50px;
}

#tBody input.iMed {
    max-width: 150px;
}

#tBody input.iBig {
    max-width: 250px;
}

#tBody input[disabled="disabled"]+* {
    color : var(--background-color-disabled);
    cursor: not-allowed;
}

#tBody [disabled="disabled"] {
    background-color: var(--background-color-disabled);
    color           : var(--color-text-secondary);
    cursor          : not-allowed;
}

#tBody [disabled="disabled"]:hover,
#tBody [disabled="disabled"]:focus {
    border : var(--border-default);
    outline: 0;
}

#tBody [type="text"]:focus,
#tBody [type="password"]:focus,
#tBody select:focus {
    outline: 1px solid var(--color-black) !important;
}

#tBody input::-ms-clear,
#tBody input::-ms-reveal {
    display: none;
}



#tBody input[type="radio"],
#tBody input[type="checkbox"] {
    -webkit-appearance: none;
    appearance        : none;
    cursor            : pointer;
    margin            : 0;
    padding           : 0;
    font-size         : 14px;
    width             : 20px;
    height            : 20px;
    vertical-align    : middle;
}

#tBody input[type="checkbox"]:checked::after {
    content    : "\e5ca";
    font-family: var(--font-family-icons);
    font-size  : 20px;
    line-height: 18px;
}

#tBody input[type="checkbox"]:checked+.checkbox-text {
    display    : inline-block;
    font-size  : 0;
    height     : 14px;
    margin-left: 4px;
    visibility : hidden;

}

#tBody input[type="checkbox"]:checked+.checkbox-text::after {
    content    : "Desactiva el check si no deseas incluir este párrafo";
    font-size  : 14px;
    margin-left: 4px;
    visibility : visible;
}

#tBody input[type="radio"] {
    border          : 1px solid var(--color-black);
    background-color: var(--color-white);
    border-radius   : 50%;
    transition      : border 0.1s ease-out;

}

#tBody input[type="radio"]:focus {
    outline: none;
}

#tBody input[type="radio"]::after {
    content         : " ";
    background-color: var(--color-white);
    border-radius   : 50%;
    display         : block;
    width           : 10px;
    height          : 10px;
    margin          : 4px;
}

#tBody input[type="radio"]:checked {
    background-color: var(--color-white);
}

#tBody input[type="radio"]:checked::after {
    background-color: var(--color-black);
}



#tBody .rBox>.rhDesc~*:not(input) {
    display: none;
}

#tBody .rBox.sel>.rhDesc~* {
    display: block !important;
    font-style:normal;
    padding-left: 2em;
}

#tBody .FO .sel .orSelect,
#tBody .FO .rBox,
#tBody .FO #dTxT>.opt.xsel div div div .opt {
    background-color: #ededed !important;
}

#tBody .FO .opt.selected,
#tBody .FO .rGroup.sel .rBox,
#tBody .FO .sel .orSelect.sel,
#tBody .FO .rBox.sel,
#tBody .FO .opt.sel,
#tBody .FO #dTxT>.opt.xsel div div div .opt.sel,
#tBody .FO .opt.xsel {
    background-color: initial !important;
}

#tBody kbd {
    padding         : 2px 4px;
    color           : var(--color-white);
    background-color: var(--color-text);
}

#tBody .hDesc {
    font-style: italic;
}

#tBody sup {
    vertical-align: super !important;
    font-size     : smaller;
}

#tBody sub {
    vertical-align: sub !important;
    font-size     : smaller;
}

/* PERSONALIZADO */

#tBody .h2-title {
    text-align: center;
    text-transform: uppercase;
    font-size: 1.3em;

}
#tBody .h3-sub-title {
    text-align: center;
    text-transform: uppercase;
    font-size: 1em;
}
#tBody .div-abstract {
    border: 1px solid var(--color-info);

}

#tBody .d1,
#tBody .d2 {
    font-weight  : 500;
    margin-top   : 3em;
    margin-bottom: 1em;
}

#tBody .d3,
#tBody .d4,
#tBody .d5 {
    font-weight  : 400;
    margin-top   : 2em;
    margin-bottom: 1em;
}

th.ancr.vntp.ac {
    border: 1px solid;
}
th.anlt.vntp.ai {
    border: 1px solid;
}