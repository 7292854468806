.ctbox-border {
    box-shadow: var(--box-shadow);
    padding: 16px;
    border: var(--border-default);
    display: block;
}

.ctbox-elevation {
    box-shadow: var(--box-shadow);
    margin: 16px;
    border: var(--border-default);
    position: relative;
}

.ctbox-block {
    display: block;
}
