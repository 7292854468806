@mixin mat-form-field($theme) {
    $mat-form-field-outline-color: var(--color-disabled);
    $mat-form-field-disabled-outline-bg-color: var(--shadow-background);
    $mat-form-field-label-color: var(--color-gray-800);
    $mat-form-field-disabled-color: var(--color-gray-500);
    $mat-form-field-invalid-color: var(--color-error);

    .form-sign {
        padding: 0 0 20px 0;
    }

    .mat-calendar-body-cell-content {
        &.mat-focus-indicator {
            position: absolute !important;
        }
    }

    .mat-radio {
        &-indent {
            padding-left: 32px;

            .mat-radio-label {
                white-space: normal !important;
            }

            &.center {
                .mat-radio-container {
                    align-self: center;
                }
            }
        }
    }

    .mat-radio-container {
        max-height: 14px;

        .mat-radio-outer-circle,
        .mat-radio-inner-circle {
            max-width: 14px;
            max-height: 14px;
        }
    }

    .mat-checkbox-inner-container,
    .mat-radio-container {

        .mat-ripple-element,
        .mat-radio-ripple {
            display: none;
        }
    }

    .mat-radio-label-content {
        line-height: initial;
        padding-left: 0;
    }

    .mat-select-arrow-wrapper {
        display: flex;
        padding: 5px 0 0 0;
    }

    .mat-form-field {
        @include sizes();
        width: 100%;
        line-height: 19px;

        &.ctbox-search-form {
            .mat-form-field-infix {
                max-width: calc(100% - 20px);
            }

            .mat-form-field-suffix {
                display: flex;
                height: 31px;
                max-width: 16px;
                top: 0;
            }

            &.double {
                .mat-form-field-infix {
                    max-width: calc(100% - 38px); //if totalResultHigh 40px
                }
            }
            &.buttonsShow {
                .mat-form-field-infix {
                    max-width: calc(100% - 74px) !important; //if totalResultHigh 120px
                }
            }
            //descomentar si se recupera la cantidad de resultados
            // &.totalResultHigh {
            //     .mat-form-field-infix {
            //         display: none;
            //         max-width: calc(100% - 135px) !important;
            //     }
            // }
        }

        &.select308 {
            @include media(XXS) {
                width: 100%;
            }

            width: 308px;
        }

        &.ctbox-tree {
            padding: 8px 0 16px 40px;
            background: transparent;
            border: none;

            .mat-form-field-infix {
                width: 100%;
            }
        }

        &.ctbox-tree-node {
            padding: 0 0 16px 0;

            .mat-form-field-infix {
                width: 100%;
            }
        }

        &.select13 {
            max-width: 130px;
        }

        &.name {
            @include media(S) {
                min-width: 190px;
            }

            min-width: 390px;

            &[appearance="none"] {

                .mat-form-field-flex,
                .mat-form-field-subscript-wrapper {
                    padding-left: 0;
                }

                .mat-error {
                    padding: 1.8em 0.5em 0 0;
                }
            }
        }

        &.number {
            max-width: 48px;

            input[matinput]::-webkit-outer-spin-button,
            input[matinput]::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            input[matinput][type="number"] {
                -moz-appearance: textfield;
            }
        }

        &.months {
            max-width: 100px;
        }

        &.date {
            width: 180px;
            max-width: 180px;

            &.big {
                .mat-form-field-infix {
                    width: 270px;
                }
            }
        }

        .mat-form-field-outline-start,
        .mat-form-field-outline-gap,
        .mat-form-field-outline-end {
            color: $mat-form-field-outline-color;
            border-width: 1px !important;
        }

        &.coment {
            .mat-input-element {
                min-height: 60px;
            }
        }

        &.days {
            max-width: 45px;

            .mat-form-field-subscript-wrapper {
                overflow: visible !important;
                min-width: 320px;
                left: -160px;
            }
        }

        &.mat-form-field-appearance-outline {

            textarea,
            input {
                border: none !important;
            }

            &.shape-oval {
                padding: 8px 0 0 0;

                .mat-form-field-suffix {
                    max-width: 24px;
                    top: 6px;
                }

                .mat-form-field-outline {
                    &-end {
                        border-radius: 0 30px 30px 0 !important;
                    }

                    &-start {
                        border-radius: 30px 0 0 30px !important;
                        flex-grow: 1;
                    }
                }
            }

            .mat-form-field-outline-start,
            .mat-form-field-outline-end {
                border-radius: 0;
            }

            .mat-form-field-infix {
                padding: 0 0 0.3em 0;
                min-width: auto;
            }

            .mat-form-field-wrapper {
                margin: 0;
                padding-bottom: 0;
            }

            .mat-error {
                padding: 1.2em 0 0 0;
                margin-bottom: 1em;
            }

            .mat-form-field-label {
                color: $mat-form-field-label-color;

                &.mat-empty {
                    margin-top: -1em;
                }
            }

            .mat-form-field-suffix {
                max-width: 16px;
                align-self: center;
            }

            &.with-hint {
                padding: 8px 0 32px 0;
            }
        }

        &.readonly {
            pointer-events: none;

            .mat-form-field-outline-start,
            .mat-form-field-outline-gap,
            .mat-form-field-outline-end {
                border-color: transparent;
            }
        }

        &.disabled,
        &.mat-form-field-disabled {
            pointer-events: none;

            .mat-form-field-outline {
                background-color: $mat-form-field-disabled-outline-bg-color;
            }

            .mat-datepicker-toggle,
            .mat-form-field-label,
            .mat-input-element {
                color: $mat-form-field-disabled-color;
            }
        }

        &.mat-focused {
            .mat-form-field-label {
                &.mat-empty {
                    margin-top: -0.25em;
                }
            }
        }

        &.mat-form-field-invalid {

            .mat-form-field-outline-start,
            .mat-form-field-outline-gap,
            .mat-form-field-outline-end,
            .mat-datepicker-toggle,
            .mat-form-field-label,
            .mat-input-element {
                color: $mat-form-field-invalid-color !important;
            }

            .ctbox-search-actions-search {
                border-left-color: $mat-form-field-invalid-color !important;
            }
        }

        .mat-hint {
            margin: 0.5em 0;
        }
    }
}