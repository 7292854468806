.updates {
    &-button {
        display: flex;
        &.warning {
            .mat-button {
                border: 1px solid var(--color-warning);
            }
        }
    }
    &-menu {
        max-width: 100% !important;
        width: 100%;
        height: 100%;
    }
    &-content {
        align-content: center;
        align-items: flex-start;
        column-gap: 32px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 8px;
    }
}
