$icono-legis-url: url("~src/assets/icons/scale-balanced.svg");

.dSF {
    line-height: 1.8em;
    margin: 0;
    padding: 16px;

    dd {
        padding: 0;
        margin: 0;
    }

    ul {
        margin: 0;
    }

    .link {
        color: var(--color-santander-dark);
        cursor: pointer;

        &:hover {
            color: var(--color-info);
            text-decoration: underline;
        }
    }

    .da {
        margin-top: .75em;

        .iLE {
            display: flex;
            align-items: flex-start;
            gap: 8px;

            &::before {
                content: "";
                display: inline-flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                background-color: var(--color-info) !important;
                mask-image: $icono-legis-url;
                min-width: 22px;
                max-height: 18px;

            }
        }
    }
}