/// cmc
///
$versions-sidenav-width: 64px;

.versions {
    &-sidenav {
        &.mat-drawer {
            display: flex !important;
            width: 25%;

            &.mat-drawer-side {
                border-right: 1px solid transparent;
            }

            .mat-drawer-inner-container {
                display: flex;
                align-items: flex-start;

                &.folded {
                    display: block;
                }
            }

            &-opened {
                min-width: 300px;
            }
        }

        &.mat-drawer[style*="visibility: hidden"],
        &:not(.mat-drawer-opened) {
            display: flex !important;
            transform: translate3d(0, 0, 0) !important;
            visibility: visible !important;
            width: $versions-sidenav-width !important;
            overflow: hidden;

            .folder-tree {
                display: none;
            }
        }

        &-button {
            display: block;

            &.opened {
                .mat-focus-indicator {
                    border-radius: 100%;
                }
            }
        }
    }

    &-header {
        display: flex;
        justify-content: space-between;
        padding: 12px 8px;
        width: 100%;

        &-buttons {
            display: flex;
            gap: 16px;
        }
    }

    &-editor {
        background-color: var(--color-white);

        &.folded {
            max-width: calc(100% - #{$versions-sidenav-width}) !important;
            margin: 0 0 0 $versions-sidenav-width !important;
        }
    }

    &-title {
        background-color: var(--color-santander-dark);
        color: var(--color-white);
        padding: 8px;
        text-align: center;
    }

    &-list {
        padding: 44px 0 0 0;

        &-component {
            display: block;
            width: 100%;
        }
    }
}