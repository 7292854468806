/// cmc
$tree-sidenav-width: 32px;

.tree {
    &-sidenav {
        &.mat-drawer {
            display: flex !important;
            width: 25%;

            @include media(XS) {
                width: 50%;
            }

            &.mat-drawer-side {
                border-right: none;
            }

            .mat-drawer-inner-container {
                display: flex !important;
            }

            &-opened {
                min-width: 180px;
            }

            &.select-folder-stepper {
                border-right: var(--border-default) !important;
            }

            &.modal-tree-text {
                width: 50%;
                height: $content-modal-tree-text;
                max-height: $content-modal-tree-text;
                min-height: $content-modal-tree-text;

                .folder-tree {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    height: inherit;

                    &-search {
                        min-height: 40px;
                        padding: 0;
                    }

                    .ctbox-tree {
                        max-height: calc(100% - 40px);
                        min-height: calc(100% - 40px);
                    }
                }
            }
        }

        &.mat-drawer[style*="visibility: hidden"],
        &:not(.mat-drawer-opened) {
            display: flex !important;
            transform: translate3d(0, 0, 0) !important;
            visibility: visible !important;
            width: $tree-sidenav-width !important;
            overflow: hidden;
        }

        &-button {
            align-items: center;
            display: flex;
            height: 32px;
            justify-content: center;
            position: absolute;
            top: 38px;
            width: 32px;

            &.opened {
                .mat-focus-indicator {
                    border-radius: 100%;
                }
            }
        }

        &.folded {

            .folder-tree-container,
            .ctbox-tree {
                display: none !important;
            }
        }

        &.result-list {
            .tree-sidenav-button {
                border-bottom: 6px solid var(--color-santander-dark);
                top: 0 !important;
                height: 40px;
            }
        }
    }

    &-header {
        display: flex;
        justify-content: space-between;
        padding: 12px 8px;
        width: 100%;

        &-buttons {
            display: flex;
            gap: 16px;
        }
    }

    &-editor {
        background-color: var(--color-white);

        &.folded {
            max-width: calc(100% - #{$tree-sidenav-width}) !important;
            margin: 0 0 0 $tree-sidenav-width !important;
        }
    }

    &-list {
        padding: 0 0 0 24px;
        width: 100%;
    }
}