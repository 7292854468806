@use "@angular/material" as mat;
@mixin mat-tab-group($theme) {
    .mat-tab-group {
        &.santander {
            border-bottom: 6px solid var(--color-santander);
            .mat-tab-label-active {
                border-bottom: 6px solid var(--color-santander-dark);
            }
        }
        &.c-help {
            .mat-tab-label {
                height: 48px;
                padding: 0 8px;
                min-width: 50px;
            }
            .mat-tab-header {
                height: 48px;
            }
            .mat-tab-body-wrapper {
                overflow: auto;
                height: calc(100% - 48px);
                max-height: 56vh;
            }
        }
    }
}
