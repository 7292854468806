.clause-in-editor {
    margin    : 5px 0;
    padding   : 5px;
    border    : 1px dotted gray;
    background: lightblue;
}

.cke_editable:not(.FLITE-Tracking) .clause-in-editor.ice-del {
    display: none;
}

.icon-alert {
    background: transparent url("../assets/icons/icon-alert.svg") 0% 35% no-repeat padding-box;
    opacity   : 1;
    padding   : 0 10px;
    border    : none;
}

.cmc-santander .clause-in-editor {
    background: #a4021a12 !important;
}