@use "@angular/material" as mat;
@mixin mat-slider($theme) {
    $height: 22px;
    $width: 48px;
    .mat-slider {
        &.three-states {
            cursor: default;
            width: $width;
            max-height: $height;
            min-width: $width !important;
            max-width: $width !important;
            padding: 0;
            .mat-slide-toggle-content {
                padding: 0;
                -webkit-touch-callout: none !important;
                -webkit-user-select: none !important;
                -moz-user-select: none !important;
                -ms-user-select: none !important;
                user-select: none !important;
            }
            &.mat-slider-disabled {
                opacity: 0.38;
            }
            .mat-slider-wrapper {
                border-radius: 16px;
                min-height: $height !important;
                min-width: $width !important;
                overflow: hidden;
                top: 0;
                left: 0;
                right: 0;
                .mat-slider-track-wrapper {
                    align-items: center;
                    background-color: var(--color-gray-300);
                    display: flex;
                    min-height: $height;
                    .mat-slider-track-fill,
                    .mat-slider-track-background {
                        display: none;
                    }
                    &::before,
                    &::after {
                        font-family: var(--font-family-icons);
                        font-size: 15px;
                        color: var(--color-white);
                        text-align: center;
                        width: 48px;
                    }
                    &::before {
                        content: "\e5cd";
                    }
                    &::after {
                        content: "\e5ca";
                    }
                }
                .mat-slider-ticks-container {
                    display: none;
                }
                .mat-slider-thumb-container {
                    min-height: $height;
                    min-width: $width;
                    top: 0;
                }
                .mat-slider-thumb {
                    color: var(--color-white);
                    background-color: var(--color-white);
                    border: none;
                    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
                    top: 3px;
                    right: -8px;
                    height: 16px !important;
                    width: 16px !important;
                    transform: unset !important;
                    &::before {
                        border-radius: 16px;
                        content: "";
                        font-family: var(--font-family-icons);
                        font-size: 15px;
                    }
                }
            }
            &.state-true {
                .mat-slider-thumb-container {
                    .mat-slider-thumb {
                        right: 4px;
                        background-color: var(--color-success);
                        &::before {
                            content: "\e5ca";
                        }
                    }
                }
            }
            &.state-false {
                .mat-slider-thumb-container {
                    .mat-slider-thumb {
                        right: -20px;
                        background-color: var(--color-error);
                        &::before {
                            content: "\e5cd";
                        }
                    }
                }
            }
            &.state-none {
                .mat-slider-thumb-container {
                    .mat-slider-thumb {
                        right: -8px;
                    }
                }
            }
            &.state-disabled {
                .mat-slider-thumb-container {
                    .mat-slider-thumb {
                        background-color: var(--color-white);
                    }
                }
            }
        }
    }
}
