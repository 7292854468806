@import 'cmc/templates.css';
@import 'cmc/embedded-clauses.css';
@import 'smartforms-template.css';

@font-face {
    font-family: "FiraSans";
    src        : url("../assets/fonts/FiraSans-Light.woff2");
    font-weight: 300;
    font-style : normal;
}

@font-face {
    font-family: "FiraSans";
    src        : url("../assets/fonts/FiraSans-Regular.woff2");
    font-weight: 400;
    font-style : normal;
}

@font-face {
    font-family: "FiraSans";
    src        : url("../assets/fonts/FiraSans-Medium.woff2");
    font-weight: 500;
    font-style : normal;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

html,
body,
div,
span,
object,
iframe,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
code,
em,
img,
small,
strike,
strong,
sub,
sup,
tt,
b,
u,
i,
ol,
ul,
li,
fieldset,
form,
label,
main,
canvas,
embed,
footer,
header,
nav,
section,
video {
    margin                : 0;
    padding               : 0;
    border                : 0;
    font-size             : 100%;
    font-family           : var(--font-family-text) !important;
    vertical-align        : baseline;
    text-rendering        : optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    text-size-adjust      : none;
}

footer,
header,
nav,
section,
main {
    display: block;
}

body {
    line-height: 1;
}

b {
    font-weight: 500;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing : 0;        
}

thead {
    background-color: var(--color-soft-shadow);;
}

th {
    padding: 0.5em;
    font-weight: normal;
}

input {
    border-radius: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-weight: 500;
    line-height: 1.2;
}


.wk-document [type="text"],
.wk-document [type="password"],
.wk-document select {
    height      : 32px;
    line-height : 32px;
    padding-left: 8px;
}

.wk-document [type="text"],
.wk-document [type="password"],
.wk-document textarea,
.wk-document select {
    border            : 1px solid #b0bec5;
    -webkit-transition: border 0.1s ease-out;
    transition        : border 0.1s ease-out;
    background-color  : #ffffff;
    border-radius     : 0;
}

.wk-document [type="text"]:hover,
.wk-document [type="password"]:hover,
.wk-document textarea:hover,
.wk-document select:hover,
.wk-document [type="text"]:focus,
.wk-document [type="password"]:focus,
.wk-document textarea:focus:not('.mat-input-element'),
.wk-document select:focus {
    border: 1px solid #000;
}

.wk-document input::-ms-clear,
.wk-document input::-ms-reveal {
    display: none;
}

.wk-document dl {
    margin: 0;
}

.wk-document dfn {
    font-style: normal;
}

.wk-document ul {
    list-style: none;
    margin    : 0;
    padding   : 0;
}

.wk-document strong {
    font-weight: 500;
}

.wk-document a {
    color : #4495cd;
    cursor: pointer;
}

.wk-document a:hover,
.wk-document a:focus {
    color          : #4495cd;
    text-decoration: none;
}

.wk-document p select {
    height       : 28px;
    margin-bottom: 2px;
    max-width    : 320px;
}

.wk-document p [type="text"] {
    height       : 24px;
    margin-bottom: 2px;
}

.wk-document p input+select {
    margin-left: 2px;
}

.wk-document input[type="radio"],
.wk-document input[type="checkbox"] {
    margin: 0;
}

.wk-document input[type="radio"],
.wk-document input[type="checkbox"] {
    border            : 1px solid #ccc !important;
    -webkit-transition: border 0.1s ease-out;
    transition        : border 0.1s ease-out;
    background-color  : #ffffff;
    margin            : 0;
    width             : 16px;
    height            : 16px;
    cursor            : pointer;
}

.wk-document input[type="checkbox"]:hover,
.wk-document input[type="radio"]:hover,
.wk-document input[type="checkbox"]:focus,
.wk-document input[type="radio"]:focus {
    border: 1px solid #000 !important;
}

.wk-document input[type="radio"][disabled="disabled"]+*,
.wk-document input[type="checkbox"][disabled="disabled"]+* {
    background-color: #ededed;
    color           : #a3a3a3;
    cursor          : not-allowed;
}

.wk-document input[type="radio"]:checked,
.wk-document input[type="checkbox"]:checked {
    background-position: center;
    background-repeat  : no-repeat;
}

.wk-document input[type="radio"] {
    border-radius: 100%;
}

.wk-document label {
    display      : inline-block;
    max-width    : 100%;
    margin-bottom: 0;
    font-weight  : inherit;
    margin-left  : 10px;
}

.wk-document form {
    margin-bottom: 0;
}

.wk-document #btc {
    margin-top: -40px;
    display   : none;
}

.wk-document #btc>* {
    line-height       : 16px;
    background-color  : #0077aa;
    padding           : 4px;
    font-size         : 12px;
    color             : #ffffff;
    -webkit-box-shadow: 4px 5px 7px -3px rgba(99, 99, 99, 0.3);
    box-shadow        : 4px 5px 7px -3px rgba(99, 99, 99, 0.3);
}

.wk-document .d2 {
    color      : #0077aa;
    font-weight: 500;
}

.wk-document #cCn {
    min-height: auto !important;
}

.wk-document #tBody {
    height: 100%;
}

.wk-document-content {
    line-height: 1.5em;
    text-align : justify;
    height     : auto;
    color      : #000000;
    width      : 100%;
}

.wk-document-content em {
    font-style: italic;
}

.wk-document-content a:hover {
    text-decoration: underline;
}

.wk-document-content a:hover em {
    color: #0077aa;
}

.wk-document-content ul {
    padding-left: 0.8em;
}

.wk-document-content p,
.wk-document-content li,
.wk-document-content h3 {
    clear: both;
}

.wk-document-content h2 {
    text-transform: uppercase;
    color         : #0077aa;
    font-size     : 14px;
    font-weight   : 500;
}

.wk-document-content h3 {
    text-align    : center;
    text-transform: uppercase;
}

.wk-document-content cite {
    margin-top: 0.5em;
    display   : block;
}

.wk-document-content [type="text"],
.wk-document-content [type="password"],
.wk-document-content select {
    width: auto;
}

.wk-document-content [type="text"]:focus,
.wk-document-content [type="password"]:focus,
.wk-document-content select:focus,
.wk-document-content [type="text"]:hover,
.wk-document-content [type="password"]:hover,
.wk-document-content select:hover {
    border-color: #000 !important;
}

.wk-document-content *+#dTxTm,
.wk-document-content *+#dHead,
.wk-document-content *+#dTxT,
.wk-document-content *+#dHPlus,
.wk-document-content *+#dNoT {
    margin-top: 3em;
}

.wk-document-content *+.a,
.wk-document-content *+.ac,
.wk-document-content *+.ai,
.wk-document-content *+.bc,
.wk-document-content *+.btnConsultas,
.wk-document-content *+.cn,
.wk-document-content *+.cCn,
.wk-document-content *+.ccn,
.wk-document-content *+.cDEs,
.wk-document-content *+.cPlus,
.wk-document-content *+.d2,
.wk-document-content *+.da,
.wk-document-content *+.dHead,
.wk-document-content *+.dAp,
.wk-document-content *+.dAb,
.wk-document-content *+.h3,
.wk-document-content *+.h5,
.wk-document-content *+.dCo,
.wk-document-content *+.dTxT,
.wk-document-content *+.fAb,
.wk-document-content *+.fCo,
.wk-document-content *+.eFi,
.wk-document-content *+.idU,
.wk-document-content *+.jCon,
.wk-document-content *+.jEn,
.wk-document-content *+.jFav,
.wk-document-content *+.jDa,
.wk-document-content *+.jDC,
.wk-document-content *+.jHN,
.wk-document-content *+.jFN,
.wk-document-content *+.jPte,
.wk-document-content *+.jPR,
.wk-document-content *+.jPB,
.wk-document-content *+.jVA,
.wk-document-content *+.jVT,
.wk-document-content *+.li,
.wk-document-content *+.pAu,
.wk-document-content *+.pEx,
.wk-document-content *+.pIt,
.wk-document-content *+.pOf,
.wk-document-content *+.Tit,
.wk-document-content *+.tPb,
.wk-document-content *+.sV1 {
    margin-top: 0.5em;
}

.wk-document-content *+div,
.wk-document-content *+p,
.wk-document-content *+h2,
.wk-document-content *+h3,
.wk-document-content *+li,
.wk-document-content *+ul,
.wk-document-content::not(.smart-forms) *+dd,
.wk-document-content::not(.smart-forms) *+dt,
.wk-document-content::not(.smart-forms) *+dl,
.wk-document-content *+cite,
.wk-document-content *+blockquote,
.wk-document-content *+table {
    margin-top: 0.5em;
    font-size : 14px;
}

.wk-document-content *+div.opt {
    margin-top: 2.2em;
}

.wk-document-content .d1 {
    color      : #000;
    font-weight: 500;
    text-align : center;
}

.wk-document-content .d2 {
    color      : #000;
    font-weight: 500;
    text-align : center;
}

.cmc-santander .wk-document-content .d1 {
    color: #ad1e1e;
}

.cmc-santander .wk-document-content .d2 {
    color: #ad1e1e;
}



.wk-document-content .nCl {
    display: none;
}

.wk-document-content .irBox {
    display: inline-block;
}

.wk-document-content .irBox input:checked~* {
    background-color: #a6d1ea;
}

.wk-document-content .irBox+p {
    display    : inline;
    margin-left: 8px;
}

.wk-document-content .rGroup {
    margin-top: 0;
}

.wk-document .AN+.d1+.rGroup>.validator-text--error {
    display: inline-block;
}

.wk-document-content .orSelect {
    position: relative;
}

.wk-document-content .orSelect .irBox {
    position: absolute;
    top     : 0;
}

.wk-document-content .rBox {
    border   : 1px solid #dadada;
    padding  : 16px 16px 16px 32px;
    position : relative;
    font-size: 14px;
}

.wk-document-content .rBox:first-of-type {
    margin-top: 32px;
}

.wk-document-content .rBox:first-of-type:before {
    content    : "Selecciona una u otra opci\00F3 n";
    position   : absolute;
    top        : 0;
    left       : 0;
    margin-top : -24px;
    line-height: 16px;
    color      : #a5a5a5;
}

.wk-document-content .radiogroup-text {
    display: none;
}

.wk-document-content .rBox:after {
    content: "";
    clear  : both;
    display: table;
}

.wk-document-content .rBox *+div {
    margin-top: 0px;
}

.rBox input[type="radio"]+.rhDesc {
    font-style: italic;
    display   : inline-block;
    padding   : 8px;
}

.wk-document .sel .orSelect.sel,
.wk-document .rBox.sel,
.wk-document .opt.sel {
    background-color: #fff;
    border          : 1px solid #b0bec5;
    padding         : 24px;
}

.wk-document .sel .orSelect,
.wk-document .opt:not(.sel):not(.xsel),
.wk-document .rBox:not(.sel):not(.xsel) {
    background-color: #eefaff;
    border          : 1px solid #b0bec5;
    padding         : 24px;
}

.cmc-santander .wk-document .sel .orSelect,
.cmc-santander .wk-document .opt:not(.sel):not(.xsel),
.cmc-santander .wk-document .rBox:not(.sel):not(.xsel) {
    background-color: #f6f6f6 !important;
    border          : 1px solid #a5a5a5;
}




.wk-document .rSelect {
    position: relative;
}

.wk-document .rSelect .orSelect {
    display     : inline-block;
    margin      : 5px 0;
    padding-left: 32px;
}

.wk-document .rSelect .orSelect .sel {
    padding-left: 32px;
}

.wk-document .rSelect .orSelect .irBox {
    top        : 30px;
    margin-left: -23px;
}

.wk-document-content .AN {
    position: relative;
    top     : -240px;
}

.wk-document-content .AN:first-child+* {
    margin-top: 0;
}

.wk-document-content .AN:first-child+[data-type]+* {
    margin-top: 0;
}

.wk-document-content .nh {
    position  : relative;
    display   : inline-block;
    width     : 20px;
    height    : 20px;
    font-style: normal;
    cursor    : default !important;
}

.wk-document-content .nh:hover+cite {
    display    : inline-block;
    margin-top : -10px;
    margin-left: 16px;
}

.wk-document-content .nh:before {
    width           : 16px;
    height          : 16px;
    top             : 0;
    bottom          : 0;
    left            : 0;
    right           : 0;
    margin          : auto;
    content         : "";
    position        : absolute;
    border-radius   : 10px;
    background-color: #0077aa;
}

.wk-document-content .nh:after {
    content    : "i";
    font-size  : 12px;
    position   : absolute;
    left       : 8px;
    color      : #ffffff;
    line-height: 22px;
}

.wk-document-content .nh dfn {
    display: none;
}

.wk-document-content .nh+cite {
    display         : none;
    line-height     : 16px;
    margin-top      : -30px;
    margin-left     : -26px;
    position        : absolute;
    background-color: #0077aa;
    padding         : 4px;
    font-size       : 12px;
    color           : #ffffff;
    z-index         : 1;
}

.wk-document-content div.opt [type="checkbox"]+span {
    position : relative;
    left     : 8px;
    top      : -4px;
    color    : #a5a5a5;
    font-size: 14px;
}

.wk-document-content #dTxT>.opt {
    border   : 1px solid #dadada;
    padding  : 24px;
    position : relative;
    font-size: 13px;
}

.wk-document-content #dNoT {
    font-size: 12px;
}

.wk-document-content #dNoT dt {
    display     : inline-block;
    margin-right: 0.8em;
}

.wk-document-content #dNoT dd {
    margin-top: 0;
    width     : auto;
    height    : auto;
}

.wk-document-content #dNoT dl+dl {
    border-top : 1px solid #cfcfcf;
    margin-top : 0.5em;
    padding-top: 0.8em;
}

.wk-document-content #dNoT dl:after {
    content: "";
    clear  : both;
    display: table;
}

.wk-document-content #dNoT .vnt {
    display    : table;
    margin-left: auto;
    margin-top : 0.5em;
}

.cke_editable .ccn em.aj,
.wk-document .ccn em.aj {
    display: none;
}

.wk-document-content .rGroup {
    font-size: 14px;
}

.wk-document em,
.wk-document em span,
.wk-document i,
.wk-document .hDesc {
    font-style: italic;
}

.wk-document span.opt {
    font-style: italic;
    color     : #999999;
    padding   : 5px 5px 5px 28px !important;
    position  : relative;
}

.wk-document span.opt input[type="checkbox"] {
    margin: 0 0 0 -21px;
    height: 14px;
}

.wk-document span.opt input[type="text"] {
    margin: 0;
    height: 19px;
}

.wk-document .rBox.wHelp>*:not(input):not(.rhDesc) {
    display: none;
}

.wk-document .rBox.wHelp.sel>* {
    display: block !important;
}

.wk-document .rBox.wHelp.sel .irBox,
.wk-document .rBox.wHelp.sel .rhDesc {
    display: inline-block !important;
    padding: 8px !important;
}

.wk-document-content input[value=""],
.wk-document-content input:not([value]),
.wk-document select[value=""],
.wk-document select:not([value]) {
    background-color: #eefaff;
    border-color    : #b0bec5;
}


.cmc-santander .wk-document-content input[value=""],
.cmc-santander .wk-document-content input:not([value]),
.cmc-santander .wk-document select[value=""],
.cmc-santander .wk-document select:not([value]) {
    background-color: #f6f6f6 !important;
    border-color    : #a5a5a5;
}

.wk-document-content a.pmG,
.wk-document-content .opt a[data-cke-pa-onclick],
.wk-document-content .opt a[onclick] {
    cursor            : pointer;
    -webkit-transition: background-color 0.1s ease-out;
    transition        : background-color 0.1s ease-out;
    text-transform    : uppercase;
    background-color  : #cfcfcf;
    color             : #ffffff;
    display           : inline-flex;
    align-items       : center;
    justify-content   : center;
    width             : 24px;
    height            : 24px;
    margin            : 4px;
    font-size         : 32px;
}

.wk-document-content a.pmG:hover,
.wk-document-content a.pmG:focus,
.wk-document-content .opt a[data-cke-pa-onclick]:hover,
.wk-document-content .opt a[data-cke-pa-onclick]:focus,
.wk-document-content .opt a[onclick]:hover,
.wk-document-content .opt a[onclick]:focus {
    background-color: rgba(0, 0, 0, 0.1);
    text-decoration : none;
    color           : #ffffff !important;
}

.wk-document-content .opt a+span {
    color    : #a5a5a5;
    font-size: 14px;
}

.wk-document-content a.pmG.minus,
.wk-document-content .opt a[data-cke-pa-onclick*="supp("],
.wk-document-content .opt a[onclick*="supp("] {
    background-color: #e5202e;
    color           : #ffffff;
}

.wk-document-content a.pmG,
.wk-document-content .opt a[data-cke-pa-onclick*="add("],
.wk-document-content .opt a[onclick*="add("] {
    background-color: #0077aa;
    color           : #ffffff;
}

.cke_editable {
    font-family: "FiraSans", Roboto, Arial, sans-serif !important;
    padding    : 16px;
    margin     : 0;
    max-width  : calc(100% - 32px);
}

.cke_editable .ice-ins {
    padding: 0 !important;
}

.cke_editable .clause-in-editor {
    margin: 1em 0 !important;
}

.warning__inline {
    display  : inline-block;
    font-size: 13px;
}

.text-one-line {
    white-space: nowrap;
    margin     : 0px 8px 0px;
}

.text-one-line.warning__inline {
    margin-left: 0;
}

.dropdown-menu-pending-required-fields {
    left: -390px !important;
}

.btn-pending-required-fields {
    margin      : -6px 0px 0px 0px;
    padding-left: 0px !important;
}

th.cpno.ancr.vnbm.ac {
    padding: 0.5em;
    font-weight: normal;
    border: none;
    text-align: left;
}

th.ancr.vnbm.ac {
    padding: 0.5em;
    font-weight: normal;
    border: none;
    text-align: left;
}
