.not-found {
    color: var(--color-info);
    font-size: 16px;
    line-height: 1.7em;
    padding: 32px;
    text-align: center;
    &.cmc{
        padding: 64px 32px;
    }
}
