.cdk-drag {
    &:not(.cdk-drag-disabled) {
        &:active {
            cursor: move;
        }
    }
    &.mat-tree-node:not(.cdk-drag-disabled) {
        user-select: none;
        cursor: move;
        &.cdk-drag-preview {
            opacity: 0 ;
        }
        &.cdk-drag-placeholder {
            background-color: var(--tree-drag-background-color);
            opacity: .75;
        }
        &:not(.cdk-drag-placeholder) {
           transition: transform 250ms cubic-bezier(.5,.5,.5,.5);
        }
        .over-drop-target,
        &.cdk-drag-animating {
            transition: transform 200ms cubic-bezier(0, 0, 0, 1);
            &:hover {
                background-color: var(--tree-drag-background-color);
            }
        }
        .mat-tree-node {
            &:hover {
                background-color: var(--tree-drag-background-color) !important;
            }
        }
        &:hover {
            background-color: var(--tree-drag-background-color) !important;
        }
    }
    &.mat-tree-node:focus-within {
        background-color: var(--tree-drag-background-color) !important;
    }
    
}
