.cke_widget_wrapper_signature-in-editor {
    display: inline-block;
}

.signature-in-editor {
    margin: 5px;
    padding: 5px;
    border: none;
    background: lightgreen;
    width: 300px;
    height: 140px;
    display: inline-block;
}

.signature-sign-placement {
    height: 100px;
    vertical-align: bottom;
}

.signature-sign-rol {
    display: none;
}

.cke_editable:not(.FLITE-Tracking) .cke_widget_insertSignature.ice-del {
    display: none;
}