.content-message {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 64px auto;

    .message {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
        background-color: var(--color-white);
        max-width: 500px;
        min-width: 30vw;
        padding: 32px;

        @media (max-width: 992px) {
            margin: 0 32px;
            max-width: 100vw;
        }

        &-icon {
            display: flex;
            flex-direction: row;
            justify-content: center;
        }

        .text {
            font-size: 1.1em;
            line-height: 1.5em;
            margin: 16px auto 0 auto;
        }

        .data {
            margin: 16px 0;
        }

        .button {
            margin-top: 32px;
        }
    }
}