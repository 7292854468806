@use "@angular/material" as mat;
@mixin mat-tab-nav-bar($theme) {
    .mat-tab-nav-bar,
    .mat-tab-header {
        border-bottom: none;
    }
    .mat-tab-list,
    .mat-tab-nav-bar {
        .mat-ink-bar {
            height: 0;
        }
        .mat-tab-label,
        .mat-tab-link {
            opacity: 1;
            border-bottom: 6px solid transparent;
        }
        &.mat-primary {
            &.santander {
                &.result {
                    .mat-tab-link {
                        max-height: 32px;
                        min-height: 32px;
                    }
                    .mat-tab-links {
                        min-height: 40px;
                        border-bottom: 6px solid var(--color-santander-dark);
                    }
                    .mat-tab-label-active {
                        border-bottom: 0;
                    }
                }
                .mat-tab-label-active {
                    border-bottom: 6px solid var(--color-santander-dark);
                }
            }
        }
        &.menu,
        &.landing-tabs {
            &.cmc-santander {
                background-color: var(--color-santander);
            }
            border-bottom: none;
            overflow: visible;
            padding: 0;
            .disabled {
                opacity: 0.5 !important;
            }
            .mat-tab-links {
                .mat-tab-label,
                .mat-tab-link {
                    color: var(--color-white);
                    font-size: 16px;
                    font-weight: 300;
                    opacity: 1;
                    text-decoration: none !important;
                    border: 0;
                    border-right: 1px solid rgba(255, 255, 255, 0.22);
                    &-active {
                        background-color: var(--color-blue-800);
                    }
                    &.free {
                        background-color: var(--color-red-500);
                        color: var(--color-white);
                        margin-left: 80px;
                        &.active {
                            background-color: var(--color-red-500);
                        }
                    }
                    &:hover {
                        background-color: var(--color-blue-400);
                    }
                }
            }
            .mat-ink-bar {
                background-color: inherit;
                height: 0;
            }
            &.Small,
            &.XSmall {
                width: 100%;
                .mat-tab-links {
                    flex-direction: column;
                    justify-content: center;
                    .free {
                        margin-left: 0;
                    }
                }
            }
        }
        &.menu {
            width: 100vw;
            .mat-tab-links {
                .mat-tab-label,
                .mat-tab-link {
                    min-height: 32px;
                    max-height: 40px;
                    &.tab {
                        border-right: 1px solid rgba(255, 255, 255, 0.22);
                    }
                    &.tab-santander {
                        background-color: var(--color-santander);
                        &.active,
                        &:hover {
                            background-color: var(--color-santander-dark);
                        }
                    }
                    &.tab-cmc-santander {
                        background-color: var(--color-santander);
                        &.active,
                        &:hover {
                            background-color: var(--color-santander-dark);
                        }
                    }
                    &.tab-administration {
                        &.cmc-santander {
                            position: absolute;
                            right: 0;
                            border-left: 1px solid rgba(255, 255, 255, 0.22);
                            background-color: var(--color-santander-black);
                            &.active,
                            &:hover {
                                background-color: var(--color-santander-dark);
                            }
                        }
                    }
                    &.logo {
                        &.Small,
                        &.XSmall {
                            display: none;
                        }
                        background-color: var(--color-text);
                        height: 40px;
                        position: absolute;
                        right: 0;
                        width: 218px;
                    }
                }
            }
            &.Medium,
            &.Small,
            &.XSmall {
                .mat-tab-links {
                    justify-content: space-between;
                    .mat-tab-label,
                    .mat-tab-link {
                        min-width: auto;
                        flex: 1;
                        &.logo,
                        &.tab-administration {
                            position: relative !important;
                            right: 0;
                        }
                    }
                }
            }
        }
        &.santander {
            .mat-tab-link {
                padding: 0 16px;
                min-height: 48px;
                min-width: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;
                gap: 8px;
            }
        }
        &.cmc-santander {
            background-color: var(--color-santander);
            .mat-tab-links {
                .mat-tab-label,
                .mat-tab-link {
                    background-color: var(--color-santander);
                    border: 0;
                    border-right: 1px solid rgba(255, 255, 255, 0.22);
                    color: var(--color-white);
                    font-size: 16px;
                    font-weight: 300;
                    opacity: 1;
                    text-decoration: none !important;
                    padding: 0 8px;
                    &-active {
                        background-color: var(--color-santander-900);
                    }
                    &:hover {
                        background-color: var(--color-santander-900);
                    }
                }
            }
        }
    }
}
