.html-viewer {
    display: block;
    padding: 16px;
    height: inherit;
    min-height: inherit;

    &-main {
        border: var(--border-default);
        box-shadow: var(--box-shadow);
        display: block;
        min-height: inherit;
        height: inherit;

        &.read-only,
        &.comment-text {
            border: none;
            box-shadow: none;
            padding: 0;
        }
    }


    &.read-only {
        pointer-events: none;
        opacity: 0.8;
        box-shadow: none;
        border: none;
    }

    &.regulations,
    &.smart-forms {
        border: none;
        box-shadow: none;
        padding: 0;
        max-height: inherit;
        width: 100%;
        height: inherit;
    }

    &.background {
        background-color: var(--shadow-background) !important;
    }

    .read-only-clause {
        background-color: transparent !important;
        display: block;
        margin: 0 !important;
        padding: 0 !important;

        .read-only-clause-buttons,
        .read-only-clause-icon,
        &::before {
            display: none;
        }


    }
}