@use "@angular/material"as mat;

@mixin mat-button($theme, $clauses: null) {
    $mat-icon-stroked-button-size: 30px;
    $mat-icon-button-size-s: 24px !important;
    $mat-icon-button-size: 32px;
    $mat-icon-button-font-size: 24px;
    $mat-icon-button-size-m: 40px;
    $mat-icon-button-size-xs: 38px;
    $color-config: mat.get-color-config($theme);

    $primary-palette: map-get($color-config, "primary");
    $warn-palette: map-get($color-config, "warn");
    $accent-palette: map-get($color-config, "accent");

    $primary-color: mat.get-color-from-palette($primary-palette, 500);
    $mat-flat-button-bg-color: $primary-color;
    $mat-stroked-button-border-color: $primary-color;
    $mat-stroked-button-icon-mat-icon-bg-color: $primary-color;
    $mat-stroked-button-color: $primary-color;
    $mat-icon-button-mat-icon-bg-color: darken(mat.get-color-from-palette($primary-palette, 500), 25%);

    $mat-stroked-button-color-warn: mat.get-color-from-palette($warn-palette, 500);
    $mat-stroked-button-color-accent: mat.get-color-from-palette($accent-palette, 500);
    $mat-icon-button-mat-icon-bg-color-warn: darken(mat.get-color-from-palette($warn-palette, 500), 25%);
    $mat-stroked-button-icon-mat-icon-bg-color-warn: darken(mat.get-color-from-palette($warn-palette, 500), 25%);
    $mat-stroked-button-icon-mat-icon-bg-color-accent: darken(mat.get-color-from-palette($accent-palette, 500), 25%);

    .mat-button,
    .mat-icon-button,
    .mat-raised-button,
    .mat-flat-button {
        line-height: $mat-icon-button-size;
        min-width: $mat-icon-button-size;
        min-height: $mat-icon-button-size;
        border-radius: 0;

        &.mat-icon-button-left {
            padding: 0;
        }

        &.mat-icon-button-right {
            padding: 0;
        }

        &.mat-primary {
            &.border {
                border: 1px solid $primary-color !important;
            }

            &.box {
                .mat-icon {
                    border-right: 1px solid;
                }
            }

            &.success {
                color: var(--color-success);
            }

            &.deleted {
                color: var(--color-delete);
            }
            &.new-text {
                color: var(--color-new-text);
            }
        }
    }

    .mat-button-icon,
    .mat-icon-button {
        align-items: center;
        display: inline-flex;
        justify-content: center;
        width: $mat-icon-button-size;
        height: $mat-icon-button-size;
        line-height: $mat-icon-button-size;
        min-width: $mat-icon-button-size !important;
        padding: 0;

        &.Small,
        &.XSmall {
            width: $mat-icon-button-size-xs;
            height: $mat-icon-button-size-xs;

            &.menu {
                color: var(--color-white);
            }
        }

        &.small {
            width: 18px !important;
            height: 18px !important;
            line-height: 18px !important;
            min-width: 18px !important;
            min-height: 18px !important;
            font-size: 18px !important;
        }

        &.xsmall {
            width: 20px !important;
            height: 20px !important;
            line-height: 20px !important;
            min-width: 20px !important;
            min-height: 20px !important;
            font-size: 20px !important;
        }

        &[class*="mat-calendar"] {
            width: $mat-icon-button-size-m;
            height: $mat-icon-button-size-m;
            line-height: $mat-icon-button-size-m;
            min-width: $mat-icon-button-size-m !important;
        }
    }

    .mat-icon {
        width: $mat-icon-button-size;
        height: $mat-icon-button-size;
        line-height: $mat-icon-button-size;
        font-size: $mat-icon-button-font-size;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            max-width: 18px;
            max-height: 24px;
        }
    }

    .mat-icon,
    .material-symbols-outlined {
        font-family: var(--font-family-icons);
        font-variation-settings:
            "FILL"0,
            "wght"400,
            "GRAD"0,
            "opsz"48;

        &.filled {
            font-variation-settings: "FILL"1;
            text-shadow: 0px 0 1px #000, 0 0px 2px #000, 0px 0px 0px #000, 0 0px #000;
        }

        &.xxs {
            width: 14px;
            height: 14px;
            min-width: 14px;
            min-height: 14px;
            font-size: 14px !important;
        }

        &.small {
            width: 16px;
            height: 16px;
            min-width: 16px;
            min-height: 16px;
            font-size: 16px !important;
        }

        &.xsmall {
            width: 20px;
            height: 20px;
            min-width: 20px;
            min-height: 20px;
            font-size: 20px !important;
        }

        &.medium {
            min-width: 36px;
            min-height: 36px;
            font-size: 36px !important;
        }

        &.big {
            min-width: 48px;
            min-height: 48px;
            font-size: 48px !important;
        }

        &.xl {
            min-width: 56px;
            min-height: 56px;
            font-size: 56px !important;
        }

        &.title {
            font-size: 32px !important;
        }

        &.warning {
            color: var(--color-warning);
        }

        &.info {
            color: var(--color-info);
        }

        &.done,
        &.true {
            color: var(--color-success);
        }

        &.error,
        &.false {
            color: var(--color-error);
        }

        &.template-clause {
            border-radius: 100%;
            border-right-color: var(--color-santander-dark);
            border-top-color: var(--color-disabled);
            border-bottom-color: var(--color-santander-dark);
            border-left-color: var(--color-disabled);
            border-width: 12px;
            border-style: solid;
            height: 0;
            width: 0;
            transform: rotate(-45deg);
        }

        &.type {
            font-family: var(--font-family-sans-serif) !important;
            font-size: 0;

            &:before {
                align-content: center;
                align-items: center;
                background-color: var(--color-disabled);
                border-radius: 50%;
                color: var(--color-white);
                content: " ";
                display: flex;
                justify-content: center;
                font-size: 12px;
                height: 22px;
                width: 22px;
            }
        }

        &.template {
            &.cmc {
                &:before {
                    color: var(--color-santander-dark);
                    background-color: var(--color-disabled);
                    content: "P";
                }
            }
        }

        &.clause {
            &.cmc {
                &:before {
                    background-color: var(--color-santander-dark);
                    content: "C";
                }
            }
        }
    }

    .mat-flat-button {
        &:not(&.mat-button-disabled) {
            &.mat-primary {
                background: $mat-flat-button-bg-color;

                .mat-icon {
                    background-color: $mat-icon-button-mat-icon-bg-color;
                }
            }

            &.mat-accent {
                background: $mat-stroked-button-color-accent;
                color: var(--color-white);

                .mat-icon {
                    background-color: $mat-stroked-button-icon-mat-icon-bg-color-accent;
                    color: var(--color-white);
                }
            }

            &.mat-warn,
            &.mat-error {
                background: var(--color-error);
                color: var(--color-white);

                .mat-icon {
                    background-color: $mat-icon-button-mat-icon-bg-color-warn;
                    color: var(--color-white);

                    svg {
                        fill: inherit;
                    }
                }
            }

            &.mat-santander {
                background: var(--color-santander);
                color: var(--color-white);

                .mat-icon {
                    color: var(--color-white);
                    background-color: var(--color-santander-dark);
                }
            }
        }
    }

    .mat-stroked-button {
        border-radius: 0;
        line-height: $mat-icon-stroked-button-size;
        min-width: $mat-icon-stroked-button-size;

        &:not(&.mat-button-disabled) {
            &.mat-primary {

                .mat-button-text-right,
                .mat-button-text-left {
                    background-color: var(--color-white);
                }

                background-color: $mat-stroked-button-color;
                border-color: $mat-stroked-button-border-color;
                color: $mat-stroked-button-color;

                .mat-icon {
                    background-color: $mat-stroked-button-icon-mat-icon-bg-color;
                    color: var(--color-white);
                }
            }

            &.mat-accent {
                border-color: $mat-stroked-button-color-accent;

                .mat-icon {
                    background-color: $mat-stroked-button-icon-mat-icon-bg-color-accent;
                    color: var(--color-white);
                }
            }

            &.mat-warn {
                border-color: $mat-stroked-button-color-warn;

                .mat-icon {
                    background-color: $mat-stroked-button-icon-mat-icon-bg-color-warn;
                    color: var(--color-white);
                }
            }

            &.done {
                border-color: var(--color-success) !important;
                color: var(--color-success) !important;

                .mat-icon {
                    background-color: var(--color-success) !important;
                    transition: all 0.5s ease-out;
                }

                transition: all 0.5s ease-out;
            }
        }

        &.mat-permission-discard {
            border-radius: 8px;
            border-color: var(--color-error);
            color: var(--color-error);
            font-size: 12px;
            line-height: 16px;
            min-height: 20px;

            &:hover {
                background-color: var(--color-error);
                color: var(--color-white);
            }
        }

        &.mat-permission-assign {
            border-radius: 8px;
            border-color: var(--color-success);
            color: var(--color-success);
            font-size: 12px;
            line-height: 16px;
            min-height: 20px;

            &:hover {
                background-color: var(--color-success);
                color: var(--color-white);
            }
        }

        .mat-icon {
            height: $mat-icon-stroked-button-size;
            width: $mat-icon-stroked-button-size;
        }

        .mat-button-text-right,
        .mat-button-text-left {
            padding: 0 16px;
        }

    }

    .mat-button-wrapper {
        display: flex;
        align-items: center;
    }

    .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix {
        .mat-icon-button {
            line-height: $mat-icon-button-size-s;
            height: $mat-icon-button-size-s;
            width: $mat-icon-button-size-s;
            max-width: $mat-icon-button-size-s;
            min-width: $mat-icon-button-size-s;
            max-height: $mat-icon-button-size-s;
            min-height: $mat-icon-button-size-s;
        }
    }

    .mat-info {
        color: var(--color-info);
    }

    .mat-success {
        color: var(--color-success);

        &.border {
            border: 1px solid var(--color-success);
        }
    }

    .mat-delete {
        color: var(--color-delete);
    }

    .mat-state {
        cursor: default;

        .mat-icon {
            color: transparent !important;
        }
    }

    .mat-true {
        color: var(--color-success);
    }

    .mat-false {
        color: var(--color-text-secondary);
    }

    .mat-datepicker-close-button {
        &::before {
            content: "\e5cd";
            display: block;
            font-family: var(--font-family-icons);
            font-size: 24px;
            line-height: 36px;
        }

        .mat-button-wrapper {
            display: none;
        }
    }

    .mat-button-disabled,
    .disabled {
        cursor: not-allowed;
        pointer-events: none !important;
        border-color: var(--color-disabled) !important;

        &.mat-icon-button-right,
        &.mat-icon-button-left {
            .mat-icon {
                background-color: var(--background-color-disabled);
            }
        }
    }

    .disabled-link {
        cursor: not-allowed;
        border-color: var(--color-disabled) !important;
        &.mat-icon-button-right,
        &.mat-icon-button-left {
            .mat-icon {
                background-color: var(--background-color-disabled);
            }
        }
    }

    .mat-button-text-right,
    .mat-button-text-left {
        padding: 0 16px;
    }

    .mat-button-text-right {
        &.filled {
            color: black;
            padding: 0 0px;
        }
    }
    .xl {

        .mat-button-text-left,
        .mat-button-text-right {
            font-size: 30px;
        }
    }

    .text-default {
        [class*="mat-button-text"] {
            color: var(--color-text);
        }

        .mat-button-text-right {
            padding: 0 8px 0 0;
        }

        .mat-button-text-left {
            padding: 0 0 8px;
        }


    }

    .cursor-default {
        cursor: default;
    }

    .mat-account {
        color: var(--color-white);

        .mat-button-wrapper {
            justify-content: center;
        }

        &-login {
            background-color: var(--color-login);

            &:hover {
                background-color: var(--color-hover-dark) !important;
            }
        }

        &-admin {
            background-color: var(--color-admin);

            &:hover {
                background-color: var(--color-hover-dark) !important;
            }
        }

        &-logout {
            background-color: var(--color-logout);

            &:hover {
                background-color: var(--color-hover-dark) !important;
            }
        }
    }

    .border-left {
        border-left: var(--border-default) !important;
    }

    .mat-menu-item {
        height: 32px;
    }

    .mat-button-focus-overlay {
        display: none !important;
    }
}


.ctbox-container-body.Small .action-info,
.ctbox-container-body.XSmall .action-info {

    .mat-icon~.mat-button-text-right,
    .mat-button-text-left~.mat-icon {
        display: none !important;
    }
}


.ctbox-search-box {

    &.Medium,
    &.Large,
    &.XLarge {
        .ctbox-search-button {
            &.mat-icon {
                display: none !important;
            }
        }
    }
}

.header-actions-menu {

    &.Small,
    &.XSmall {

        .mat-button-text-right,
        .mat-button-text-left {
            display: none !important;
        }
    }
}

.progress_activity,
.refresh {
    .mat-button-wrapper {
        .mat-icon {
            -webkit-animation: refresh 0.8s linear infinite;
            -moz-animation: refresh 0.8s linear infinite;
            animation: refresh 0.8s linear infinite;
            border-radius: 50%;
        }
    }
}

@-moz-keyframes refresh {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes refresh {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes refresh {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
