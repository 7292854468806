$ctbox-main-height: 100vh;
$ctbox-header-height: 104px;
$ctbox-main-modal-height: 80vh;
$ctbox-header-actions: 64px;
$ctbox-header-menu: 40px;
$ctbox-container-header-height: $ctbox-header-actions+$ctbox-header-menu;
$ctbox-container-height: calc($ctbox-main-height - $ctbox-container-header-height);
$ctbox-title-height: 40px;
$ctbox-tabsbar-height: 49px;
$ctbox-tabsbar-title-height: $ctbox-title-height+$ctbox-tabsbar-height;
$ctbox-actionbar-height: 40px;
$ctbox-actions-height: 40px;
$ctbox-stepper-height: 52px;
$ctbox-nav-height: 40px;
$ctbox-title-bar-tabs-height: 38px;
$ctbox-tabsbar-paginator-height: 40px;
$main-height: calc(#{$ctbox-container-height} - #{$ctbox-container-header-height} - #{$ctbox-nav-height});
$with-title-tabs: $ctbox-container-header-height+$ctbox-title-height+$ctbox-tabsbar-height;
$with-title-tabs-actions: $ctbox-container-header-height+$ctbox-title-height+$ctbox-tabsbar-height+$ctbox-actions-height+1;
$with-title-actions: $ctbox-container-header-height+$ctbox-title-height+$ctbox-actions-height;
$with-title: $ctbox-container-header-height+$ctbox-title-height;
$with-title-bar-tabs: $ctbox-container-header-height+$ctbox-tabsbar-height+$ctbox-title-bar-tabs-height;
$with-tabs-actions: $ctbox-container-header-height+$ctbox-tabsbar-height+$ctbox-actions-height;
$with-tabs: $ctbox-container-header-height+$ctbox-tabsbar-height;
$with-tabs-tabs: $ctbox-container-header-height+$ctbox-tabsbar-height+$ctbox-tabsbar-height;

$with-tabs-title-tabs-actions: $ctbox-container-header-height+$ctbox-tabsbar-height+$ctbox-title-bar-tabs-height+$ctbox-tabsbar-height+$ctbox-actions-height+3px;
$with-tabs-title-tabs: $ctbox-container-header-height+$ctbox-tabsbar-height+$ctbox-title-bar-tabs-height+$ctbox-tabsbar-height;
$with-stepper: $ctbox-container-header-height+$ctbox-stepper-height;
$content-with-title: calc(#{$ctbox-main-height} - #{$with-title});
$content-with-title-tabs: calc(#{$ctbox-main-height} - #{$with-title-tabs});
$content-with-title-tabs-actions: calc(#{$ctbox-main-height} - #{$with-title-tabs-actions});
$content-with-title-tabs-actions-editor: calc(#{$ctbox-main-height} - #{$with-title-tabs-actions} - 100px);
$content-with-title-bar-tabs: calc(#{$ctbox-main-height} - #{$with-title-bar-tabs});
$content-with-title-actions: calc(#{$ctbox-main-height} - #{$with-title-actions});
$content-with-title-actions-editor: calc(#{$ctbox-main-height} - #{$with-title-actions} - 72px);
$content-with-tabs-actions: calc(#{$ctbox-main-height} - #{$with-tabs-actions});
$content-with-stepper: calc(#{$ctbox-main-height} - #{$with-stepper});
$content-modal-tree-text: calc(#{$ctbox-main-modal-height} - 48px);
$content-with-tabs: calc(#{$ctbox-main-height} - #{$with-tabs});
$content-with-tabs-tabs: calc(#{$ctbox-main-height} - #{$with-tabs-tabs});
$content-with-tabs-title-tabs-actions: calc(#{$ctbox-main-height} - #{$with-tabs-title-tabs-actions});
$content-with-tabs-title-tabs: calc(#{$ctbox-main-height} - #{$with-tabs-title-tabs});

body.root {
    overflow: auto;
}

.ctbox-container {
    min-height: $main-height;

    .ctbox-nav {
        height: $ctbox-nav-height;
    }

    .root {
        min-height: $main-height;
    }
}

.content-main {
    display: flex;
    flex-direction: column;
    overflow: auto;
    position: relative;
    min-width: 100%;
    padding: 0 24px 0 24px;

    &.with-tabs {
        height: $content-with-tabs;
        max-height: $content-with-tabs;
    }

    &.with-tabs-tabs {
        height: $content-with-tabs-tabs;
        max-height: $content-with-tabs-tabs;
    }

    &.with-tabs-actions {
        height: $content-with-tabs-actions;
        max-height: $content-with-tabs-actions;
    }

    &.with-title {
        height: $content-with-title;
        max-height: $content-with-title;
    }

    &.with-title-tabs {
        height: $content-with-title-tabs;
        max-height: $content-with-title-tabs;

        .cke_contents {
            min-height: $content-with-title-tabs;
        }
    }

    &.with-title-actions {
        height: $content-with-title-actions;
        max-height: $content-with-title-actions;
        min-height: $content-with-title-actions-editor;

        .cke_contents {
            min-height: inherit;
        }
    }

    &.with-title-tabs-actions {
        height: $content-with-title-tabs-actions;
        max-height: $content-with-title-tabs-actions;
        min-height: $content-with-title-tabs-actions-editor;

        .cke_contents {
            min-height: inherit;
        }
    }

    &.with-tabs-title-tabs {
        height: $content-with-tabs-title-tabs;
        max-height: $content-with-tabs-title-tabs;
        min-height: $content-with-tabs-title-tabs;

        .cke_contents {
            min-height: inherit;
        }
    }

    &.with-tabs-title-tabs-actions {
        height: $content-with-tabs-title-tabs-actions;
        max-height: $content-with-tabs-title-tabs-actions;
        min-height: $content-with-tabs-title-tabs-actions;

        .cke_contents {
            min-height: inherit;
        }
    }

    &.with-title-bar-tabs {
        height: $content-with-title-bar-tabs;
        max-height: $content-with-title-bar-tabs;
        min-height: $content-with-title-bar-tabs;

        .cke_contents {
            min-height: inherit;
        }
    }

    &.with-tabs-stepper {
        height: $content-with-stepper;
        max-height: $content-with-stepper;
        padding: 0;
    }

    &.overflow-hidden,
    &.resized {
        overflow: hidden;
        padding: 0;
    }

    &.smart-forms {
        padding: 0 0 0 24px;
    }

    &.with-stepper,
    &.with-editor,
    &.with-sidenav,
    &.with-table-sticky {
        padding: 0;
    }

    &.ctbox-elevation {
        min-width: calc(100% - 32px);
        padding: 0;
    }

    &.home,
    &.dashboard,
    &.main {
        height: $ctbox-container-height;
        max-height: $ctbox-container-height;
        padding: 16px;
    }

    &-list {
        max-width: 100%;
        min-width: 100%;
        min-height: inherit;
    }

    &.result-list {
        padding: 0;
        min-width: auto;
    }



    .left-side {
        @include sizes();
        @include resizes();
        flex-grow: 0;
        height: inherit;
        max-height: inherit;
        overflow: auto;

        &.lld {
            padding: 0 16px;
        }

        &.tree {
            width: 100%;
            min-width: 100%;
        }

    }

    .right-side {
        @include sizes();
        @include resizes();
        flex-grow: 0;
        height: inherit;
        max-height: inherit;
        overflow: auto;

        &.tree {
            padding: 0 16px;
        }
    }

    .border-top {
        border-top: var(--border-default);
    }

    .border-right {
        border-right: var(--border-default);
    }

    .border-left {
        border-right: var(--border-default);
    }

    .border-default {
        border: var(--border-default);
    }

    .html-viewer-main {
        height: auto;
        min-height: auto;
        border: none;
        box-shadow: none;
    }

    .not-found {
        border: var(--border-default);
        height: inherit;
        margin-top: 39px;
    }

}

.resizable {
    @include resizes();
    border-right-width: 3px;
    border-right-style: solid;
    border-right-color: var(--border-color-default);
    display: flex;
    height: inherit;
    max-height: inherit;
    overflow: auto;

    &:hover,
    &:active,
    &:focus-visible {
        border-right-width: 3px;
        border-right-style: solid;
        border-right-color: var(--color-gray-400);
    }
}