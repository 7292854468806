@mixin mat-expansion($theme) {
    .mat-expansion-panel {
        &.workflow {
            &:not(.mat-expanded) {
                .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
                    background: transparent;
                }
            }
            .mat-expansion-panel-header {
                color: var(--color-info);
                padding: 0 2px;
                &.mat-expanded {
                    max-height: 32px;
                }
            }
            .mat-content {
                &::before {
                    content: "";
                    border-bottom: 1px solid var(--color-info);
                    bottom: 50%;
                    position: absolute;
                    width: 100%;
                }
            }
            .mat-expansion-panel-header-title {
                color: var(--color-info);
                label {
                    background: var(--color-white);
                    padding-right: 16px;
                    z-index: 1;
                }
            }
            .mat-expansion-indicator {
                background: var(--color-white);
                z-index: 1;
                &::after {
                    color: var(--color-info);
                }
            }
            .mat-expansion-panel-body {
                padding: 0;
            }
        }
        &.list-clauses {
            border-bottom: var(--border-default);
            &.mat-expansion-panel-spacing {
                margin: 0;
            }
            .mat-expansion-panel-header-title {
                color: var(--color-default);
            }
            .mat-expansion-panel-header-description {
                padding: 12px;
                line-height: 24px;
            }
        }
    }
}
