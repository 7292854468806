@mixin mat-tooltip($theme) {
    .mat-tooltip {
        background-color: var(--color-white);
        border: var(--border-default);
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 10%);
        color: var(--color-text-secondary) !important;
        font-size: 13px;
        margin: 0;
        max-width: 450px !important;
        padding: 8px !important;
    }
}
