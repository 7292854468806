.wysiwyg {
    align-items: flex-start;
    align-content: flex-start;
    box-shadow: var(--box-shadow);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: space-between;
    max-height: inherit;
    min-height: inherit;
    width: 100%;
    &-hidden {
        display: none !important;
    }
    &-main {
        flex: 1;
        width: 100%;
        height: inherit;
    }
    &-ckeditor {
        display: block;
        min-height: inherit;
        height: inherit;
    }
    &-editor {
        width: 100%;
        flex: 1;
        min-height: inherit;
        &.width-comments {
            width: calc(75% - 8px);
        }
    }
    &-comments {
        &-hidden {
            display: none;
        }
        width: calc(25% - 8px);
        .comments {
            border: var(--border-default);
            &-title {
                font-size: 16px;
                font-weight: 500;
                padding: 16px;
                background-color: var(--shadow-background);
            }
            &-button {
                background-color: var(--shadow-background);
                border-bottom: var(--border-default);
                padding: 0 16px 16px 16px;
            }
            &-text {
                background: var(--color-white);
                border-top: var(--border-default);
                color: var(--color-text-secondary);
                font-size: 16px;
                line-height: 24px;
                padding: 24px 40px;
                text-align: center;

                .cke_button_icon {
                    width: 24px;
                    height: 24px;
                    float: none;
                }
                .cke_button__annotate_icon {
                    background-image: url("~src/plugins/lance-tmce/lance/icons/add-comment_cke.png");
                }
            }
            &-container {
                background-color: var(--shadow-background);
            }
            &-top {
                margin: 0;
            }
            .lance-annotations-container {
                border: none !important;
            }
        }
    }
    &-container {
        margin: 0;
        min-height: inherit;
        position: relative;
        width: 100%;
        &-error {
            background-color: var(--shadow-background);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
        }
    }

    .cke_dialog_contents {
        input[type="radio"],
        input[type="checkbox"] {
            border: var(--border-default) !important;
            -webkit-transition: border 0.1s ease-out;
            transition: border 0.1s ease-out;
            background-color: var(--color-white);
            margin: 0;
            width: 16px;
            height: 16px;
            cursor: pointer;
        }
        input[type="checkbox"]:hover,
        input[type="radio"]:hover,
        input[type="checkbox"]:focus,
        input[type="radio"]:focus {
            border: 1px solid var(--color-black) !important;
        }
        input[type="radio"][disabled="disabled"] + *,
        input[type="checkbox"][disabled="disabled"] + * {
            background-color: var(--color-gray-200);
            color: var(--color-gray-500);
            cursor: not-allowed;
        }
        input[type="radio"]:checked,
        input[type="checkbox"]:checked {
            background-position: center;
            background-repeat: no-repeat;
        }
        input[type="radio"] {
            border-radius: 8px;
        }
    }
    .cke_button_icon {
        background-size: 16px !important;
    }
    .cke_button_on {
        background: var(--color-disabled) !important;
    }
    .cke_button__clausefromlibrary_icon {
        background-image: url("~src/assets/icons/embedsemantic.png") !important;
    }
    .cke_button__insertclauseandmodal_icon {
        background-image: url("~src/assets/icons/form.png") !important;
    }
    .cke_button__clausefromtree_icon {
        width: 14px;
        background-color: var(--color-info) !important;
        mask-image: url("~src/assets/icons/square-list.svg") !important;
    }
    .cke_button__helpnotes_icon {
        background-color: var(--color-info) !important;
        mask-image: url("~src/assets/icons/helpnotes.svg") !important;
    }
    .cke_button__insertsignatureandmodal_icon {
        width: 18px;
        height: 16px;
        background-color: var(--color-info) !important;
        mask-image: url("~src/assets/icons/signature.svg") !important;
    }
    .cke_button__maximize_icon {
        background-image: none !important;
        background-color: var(--color-info) !important;
        mask-image: url("~src/assets/icons/ico-maximize.svg") !important;
    }
    .cke_button__flite-acceptall_icon {
        background-image: url("~src/plugins/flite-cke/flite/icons/flite-acceptall.png");
    }
    .cke_button__flite-acceptone_icon {
        background-image: url("~src/plugins/flite-cke/flite/icons/flite-acceptone.png");
    }
    .cke_button__flite-rejectall_icon {
        background-image: url("~src/plugins/flite-cke/flite/icons/flite-rejectall.png");
    }
    .cke_button__flite-rejectone_icon {
        background-image: url("~src/plugins/flite-cke/flite/icons/flite-rejectone.png");
    }
    .cke_button__flite-toggleshow_icon {
        background-image: url("~src/plugins/flite-cke/flite/icons/flite-toggleshow.png");
    }
    .cke_button__nextchangeflitebutton_icon {
        background-image: url("~src/plugins/flite-cke/flite/icons/flite-nextchange.png");
    }
    .cke_button__previouschangeflitebutton_icon {
        background-image: url("~src/plugins/flite-cke/flite/icons/flite-prevchange.png");
    }
    .cke_button__annotate_icon {
        background-image: url("~src/plugins/lance-tmce/lance/icons/add-comment_cke.png");
    }
    .cke_button__textfield_icon {
        background: url("~src/plugins/skins/office2013/icons.png") no-repeat 0 -1968px !important;
    }
    .cke_button__select_icon {
        background: url("~src/plugins/skins/office2013/icons.png") no-repeat 0 -1512px !important;
    }
    .cke_button__checkbox_icon {
        background: url("~src/plugins/skins/office2013/icons.png") no-repeat 0 -288px !important;
    }
    .cke_button__radio_icon {
        background: url("~src/plugins/skins/office2013/icons.png") no-repeat 0 -1320px !important;
    }

    .cke_toolbar_start {
        display: none;
    }
    .cke_top {
        display: flex;
        flex-wrap: wrap;
        border-top: var(--border-default);
        border-bottom: var(--border-default);
    }
    .cke_toolbox {
        display: flex;
        flex-wrap: wrap;
    }

    .version-editor-without-track-changes {
        .cke_top,
        .cke_toolbox {
            display: none;
        }
    }

    .cke_toolbar {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 0 5px 0 5px;
        .cke_toolgroup {
            display: flex;
            align-items: center;
            margin: 0;
            min-height: 30px;
            &::after {
                border-right: 2px solid var(--border-color-default);
                content: "";
                display: block;
                margin: 0;
                min-height: 20px;
            }
        }
        .cke_toolbar_separator {
            border-right: 2px solid var(--border-color-default);
            display: block;
            margin: 0;
            min-height: 20px;
            width: 0;
        }

        .cke_voice_label {
            &[data-voice_label] ~ .cke_toolgroup {
                &::before {
                    display: block !important;
                }
            }
            &[data-voice_label="clauses"] ~ .cke_toolgroup {
                &::before {
                    content: "Cláusulas";
                }
            }
            &[data-voice_label="Formularios"] ~ .cke_toolgroup {
                &::before {
                    content: "Opciones";
                }
            }
            &[data-voice_label="signature"] ~ .cke_toolgroup {
                &::before {
                    content: "Firma";
                }
            }
            &[data-voice_label="Herramientas"] ~ .cke_toolgroup {
                &::before {
                    content: "Maximizar";
                }
            }
            &[data-voice_label="flite"] ~ .cke_toolgroup {
                &::before {
                    content: "Control de cambios";
                }
            }
        }
    }

    .cke_inner {
        border: var(--border-default);
        border-top: 0;
        height: inherit;
    }

    a.cke_button_off:hover,
    a.cke_button_off:focus,
    a.cke_button_off:active,
    a.cke_button_disabled:hover,
    a.cke_button_disabled:focus,
    a.cke_button_disabled:active {
        background: var(--color-disabled) !important;
    }

    .cke_maximized {
        .cke_top {
            background: var(--shadow-background);
            min-height: 68px;
        }
    }

    .text-clause p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .text-clause {
        max-width: 640px;
        max-height: 0;
    }

    .cke_button__creatediv {
        display: none !important;
    }

    .mark-clause {
        margin: 5px;
        padding: 5px;
        background: var(--color-blue-A200);
        border: none;
    }
}

.ccn em.aj {
    display: none;
}
