.smart-forms .wk-document,
.smart-forms .wk-document .FO {
    display: flex;
    max-height: inherit;
    max-width: inherit;
    font-size: 14px;
    height: inherit;
}

.smart-forms .wk-document .FO #cCx {
    border: var(--border-default);
    box-shadow: 4px 7px 10px rgba(0, 0, 0, .4);
    max-height: inherit;
    min-height: inherit;
    max-width: calc(100vw - 8px);
    overflow: auto;
    transition: width .3s ease;
    width: 100%;
}

.smart-forms .wk-document .FO.view-text #cCx {
    max-height: inherit;
    max-width: calc(50vw - 8px);
    transition: width 3s ease;
    width: 50%;
}

.smart-forms .wk-document .FO #cCn {
    min-height: inherit;
    max-height: inherit;
    min-height: 300px !important;
    overflow: hidden;
    width: 34px;
}

.smart-forms .wk-document .FO.view-text #cCn {
    background-color: var(--shadow-background);
    border: var(--border-default);
    max-width: calc(50vw - 8px);
    min-height: inherit;
    overflow: auto;
    width: 100%;
    box-shadow: inset 10px 0px 10px -10px rgba(0, 0, 0, .4);
}

.smart-forms .wk-document .FO #cCn::before {
    color: var(--color-gray-400);
    content: 'Texto del modelo';
    display: block;
    margin: 64px 0;
    max-width: 32px;
    padding: 0;
    position: fixed;
    transform: rotate(90deg);
    white-space: nowrap;
}

.smart-forms .wk-document .FO.view-text #cCn::before {
    max-width: 48px;
}

.smart-forms .wk-document .FO #cCn .logo {
    align-content: center;
    align-items: center;
    background-color: var(--color-white);
    border: var(--border-default);
    color: var(--color-gray-400);
    cursor: pointer;
    display: flex;
    font-family: var(--font-family-icons);
    font-size: 30px;
    justify-content: center;
    position: absolute;
    top: 0;
    z-index: 1;
    width: 32px;
    height: 32px;
}

.smart-forms .wk-document .FO #cCn .logo::before {
    content: '\eac3';
    font-family: var(--font-family-icons);
}

.smart-forms .wk-document .FO #cCn .logo:hover {
    color: var(--color-blue-500);
}

.smart-forms .wk-document .FO.view-text #cCn .logo {
    background-color: transparent;
    border: none;
    color: var(--color-blue-500);
    top: 8px;
    width: 48px;
}

.smart-forms .wk-document .FO.view-text #cCn .logo::before {
    content: '\eac9';
    font-family: var(--font-family-icons);
}

.smart-forms .wk-document .FO.view-text #cCn .logo:hover {
    color: var(--color-text-secondary);
}

.smart-forms .wk-document .FO #cCn>#dTxT {
    height: inherit;
    display: none;
    width: 0;
}

.smart-forms .wk-document .FO.view-text #cCn #dTxT {
    background-color: var(--color-white);
    border: var(--border-default);
    display: block;
    margin: 16px 32px 16px 48px;
    padding: 32px 48px;
    width: calc(100% - 80px);
}


/* */

.smart-forms .wk-document .FO #cCx #queries .h2,
.smart-forms .wk-document .FO #cCx #queries .h3,
.smart-forms .wk-document .FO #cCx #queries .h4,
.smart-forms .wk-document .FO #cCx #queries .h5,
.smart-forms .wk-document .FO #cCx #queries fieldset:not(.rG),
.smart-forms .wk-document .FO #cCx #queries .qF .sl,
.smart-forms .wk-document .FO #cCx #queries .query .pmG,
.smart-forms .wk-document .FO #cCx #queries .query .in,
.smart-forms .wk-document .FO #cCn .gt,
.smart-forms .wk-document .FO #cCx #cVe,
.smart-forms .wk-document .FO #cCx #wSouth,
.smart-forms .wk-document .FO #dHead,
.smart-forms .wk-document .FO #fondoRight,
.smart-forms .wk-document .FO #barraBtn,
.smart-forms .wk-document .FO #txtFinish,
.smart-forms .wk-document .FO #cPx,
.smart-forms .wk-document .FO #cPx #steps {
    display: none;
}

.smart-forms .wk-document .FO #cCx #queries {
    padding: 0 32px 32px 32px;
    margin: 0;
}

.smart-forms #tBody #cCx {
    display: block;
}

/* RADIOGROUP  */

.smart-forms #cCx #queries .query .rG {
    margin: 20px 0;
    border-left: 1px solid var(--color-text-secondary);
}

.smart-forms #cCx #queries .query .rG .rG {
    margin: 10px 0;
    border-left: var(--border-default);
}

.smart-forms #cCx #queries .query .rG>label.gt::after {
    color: var(--color-error);
    content: '*';
    display: block;
    font-weight: 500;
    font-size: 24px;
}

.smart-forms #cCx #queries .query .rG.done>label.gt::after {
    color: var(--color-success);
    content: '\e876';
    display: block;
    font-weight: 500;
    font-family: var(--font-family-icons);
    font-size: 24px;
}

.smart-forms #cCx #queries .query .rG label.gt {
    font-weight: 500;
    font-size: 1.2;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
}

.smart-forms #cCx #queries .query .rG dl:not(.cB)>dt {
    display: flex;
    gap: 8px;
    flex-direction: row;
    align-items: flex-start;
    align-content: center;
    justify-content: flex-start;
    margin: 0;
    padding: 4px 16px;
}

.smart-forms #cCx #queries .query .rG dl dt:hover {
    background-color: var(--shadow-background);
}

.smart-forms #cCx #queries input[type="radio"] {
    height: 18px;
    width: 18px;
    min-height: 18px;
    min-width: 18px;
    appearance: initial;
    background-image: none;
    background-color: var(--color-white);
    border: 5px solid var(--color-white) !important;
    outline: 2px solid var(--color-gray-300);
}

#tBody input[type="radio"]::after {
    content: " ";
    border-radius: 50%;
    display: block;
    width: 8px;
    height: 8px;    
    margin: 0px;
}

.smart-forms #cCx #queries input[type="radio"]:checked,
.smart-forms #cCx #queries input[type="radio"]:hover,
.smart-forms #cCx #queries input[type="radio"]:focus {
    background-color: var(--color-success);
    outline: 2px solid var(--color-success);
}


/* CHECKBOX */

.smart-forms #cCx #queries .query .cB>dt {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 4px;
    margin-top: -32px;
    width: 100%;
    margin: 0;
    padding: 6px 16px;
}

.smart-forms #cCx #queries .query .cB>dt:hover,
.smart-forms #cCx #queries .query .cB>dt:focus {
    background-color: var(--shadow-background);
}

.smart-forms #cCx #queries .query .cB label.gt {
    font-weight: 500;
    width: 100%;
}

.smart-forms #cCx #queries input[type="checkbox"] {
    appearance: initial;
    align-content: center;
    align-items: center;
    align-self: flex-start;
    background-image: none;
    background-color: var(--color-white);
    border-color: var(--color-white) !important;
    display: flex;
    justify-content: center;
    height: 18px;
    min-height: 18px;
    min-width: 18px;
    outline: var(--border-default);
    width: 18px;
}

.smart-forms #cCx #queries input[type="checkbox"]:checked {
    background-color: var(--color-white);
    outline: 2px solid var(--color-success);
    color: var(--color-success);
}

.smart-forms #cCx #queries input[type="checkbox"]:hover {
    outline: 2px solid var(--color-gray-300);
    color: var(--color-gray-300);
}

.smart-forms #cCx #queries input[type="checkbox"]:checked::after,
.smart-forms #cCx #queries input[type="checkbox"]:hover::after {
    content: '\e876';
    font-family: var(--font-family-icons);
    font-size: 22px;
    font-weight: 400;
}


/*  */

.smart-forms #cCx .h1 {
    align-items: center;
    align-content: center;
    background-color: var(--color-white);
    border-bottom: var(--border-default);
    display: flex;
    font-size: 1.2em;
    font-weight: 500;
    justify-content: center;
    margin: 0;
    padding: 16px 0;
    position: sticky;
    top: 0;
    z-index: 1;
}

.smart-forms #cCx #queries .query {
    margin: 0;
    padding: 0;
    display: block;
}

.smart-forms dt label {
    font-weight: 300;
}

.smart-forms #cCx #queries .query dl dd.disabled {
    display: none;
}

.smart-forms #cCx #queries .query dl dd.enabled {
    display: block;
    margin: 0;
}

.smart-forms #cCx #queries .query dl dd.enabled.op {
    margin: 0 0 0 48px;
}


/* texto #cCn */


/* CHECKBOX dentro de PMG */

.smart-forms .wk-document .FO #cCn .pmG b {
    color: var(--color-white);
}

.smart-forms .wk-document .FO #cCn .pmG>.cB .gt {
    display: inline-block;
}

.smart-forms .wk-document .FO #cCn .pmG .cB dt {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
}


/* Radiobox dentro de PMG */

.smart-forms .wk-document #cCn .cl {
    display: none;
    transition: all 1s ease;
    width: 100%;
}

.smart-forms .wk-document .FO #cCn .pmG>.rG.disabled.cl.done {
    display: inline-block !important;
    transition: all 2s ease;
    width: 100%;
}

.smart-forms .wk-document .FO #cCn .cle {
    color: var(--color-gray-400);
}

.smart-forms .wk-document .FO #cCn .op,
.smart-forms .wk-document .FO #cCn .hle,
.smart-forms .wk-document .FO #cCn .ope {
    display: inline-block;
    width: 100%;
    animation-name: cambiarColor;
    animation-duration: 1s;
    animation-iteration-count: 1;
}

@-webkit-keyframes cambiarColor {
    0% {
        background-color: var(--color-gray-300);
    }
    50% {
        background-color: var(--shadow-background);
    }
    100% {
        background-color: transparent;
    }
}

@keyframes cambiarColor {
    0% {
        background-color: var(--color-gray-300);
    }
    50% {
        background-color: var(--shadow-background);
    }
    100% {
        background-color: transparent;
    }
}

.smart-forms .wk-document .FO #cCn fieldset.sl {
    display: inline-block;
    margin: 0 8px;
    max-width: 70vh;
    position: relative;
}

.smart-forms .wk-document .FO #cCn fieldset.in {
    display: inline-flex;
    flex-direction: row-reverse;
    gap: 8px;
    position: relative;
}

.smart-forms .wk-document .FO #cCn fieldset.sl select.sl {
    max-width: inherit;
}

.smart-forms .wk-document #cCn a.hd {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    font-style: normal;
}

.smart-forms .wk-document .nh+cite.nOp {
    font-style: normal;
    margin: 0;
    padding: 16px;
    position: absolute;
    top: 24px;
}

.smart-forms .wk-document em,
.smart-forms .wk-document i {
    font-style: normal;
}

.smart-forms .wk-document #cCn .isf {
    width: auto;
}

.smart-forms .wk-document #cCn p {
    line-height: 2em;
    margin: 1em 0;
}

.smart-forms .h1,
.smart-forms .h2,
.smart-forms .h3,
.smart-forms .h4,
.smart-forms .h5,
.smart-forms .h6 {
    font-size: 1em;
    margin: .5em 0;
}

.smart-forms .wk-document #cCn cite.ccn {
    display: block;
    background: none;
    border: 1px solid var(--color-blue-500);
    margin: 20px 0;
    line-height: 20px;
    padding: 10px;
    font-style: normal;
}

.smart-forms .wk-document #cCn .ccn q {
    display: inline-block;
    padding: 0;
}

.smart-forms #cCx #queries .query dd {
    padding: 0;
    margin: 0;
}