@use '@angular/material' as mat;
@mixin mat-progress-bar($theme) {
    .actions-progress-bar {
        height: 32px;
        padding: 0 16px;
        .progress-result {
            display: flex;
            gap: 16px;
        }
        .progress-done {
            font-weight: 400;
        }
        .mat-progress-bar.smart-forms {
            width: 100%;
            height: 10px;
            border-radius: 12px;
            margin: 6px 0;
        }
    }
}
