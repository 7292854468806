@use "@angular/material"as mat;

@mixin mat-stepper($theme) {
    .mat-horizontal-stepper-header {
        height: 52px;
    }

    .mat-horizontal-content-container {
        padding: 0;
    }

    .mat-stepper-horizontal-line {
        border-color: var(--color-info);
        border-style: dotted;
    }

    .mat-step {
        &-header {
            pointer-events: none !important;
        }
    }
}
