.viewer {
    display: flex;
    flex-direction: column;
    max-height: inherit;
    height: inherit;
    &-main {
        display: block;
        height: inherit;
        max-height: inherit;
        overflow-wrap: break-word;
    }
    .original-clasuse {
        background-color: var(--color-blue-A200) !important;
        height: auto !important;
        margin: 16px;
    }
    &-info {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        gap: 8px;
        height: 52px;
        width: 100%;
    }
    &-header {
        min-height: 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        &.comparation {
            display: flex;
            flex-direction: row;
            max-height: inherit;
            gap: 16px;
        }
    }
    &-body {
        max-height: calc(90vh - 120px);
        min-height: calc(90vh - 156px);
        height: 65vh;
        width: 100%;
        overflow: auto;
        &.header {
            max-height: calc(90vh - 172px);
            min-height: calc(90vh - 172px);
            height: calc(90vh - 172px);
        }
        &.comparation {
            display: flex;
            flex-direction: row;
            max-height: inherit;
            gap: 16px;
        }
    }
    &-actions {
        display: flex;
        justify-content: flex-end;
        flex: 1;
        gap: 8px;
    }
    &-pagination-number {
        font-size: 14px;
        font-weight: 400;
    }
    &-pagination {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    &-error {
        background-color: var(--color-white);
        color: var(--color-error);
        font-weight: 400;
        padding: 8px;
        position: sticky;
        top: 0;
        z-index: 1;
        &-sub {
            color: var(--color-error);
            padding-left: 4px;
        }
    }
    &-html {
        border: var(--border-default);
        overflow: auto;
        width: 100%;

        &.overflow {
            height: inherit;
            max-height: initial;
            min-height: initial;
            overflow: hidden;
        }
        .html-viewer-main:not(.overflow),
        .wysiwyg-editor,
        .cke_inner,
        .cke_chrome,
        .cke_contents {
            height: inherit !important;
        }
        .html-viewer-main {
            border: none;
            box-shadow: none;
        }
    }

    .size-50 {
        height: inherit;
        max-width: calc(50% - 8px);
        .wysiwyg {
            height: inherit !important;
        }
    }
    &-comment {
        border-bottom: var(--border-default);
        background-color: var(--color-white) !important;
        position: -webkit-sticky;
        position: sticky;
        padding: 10px;
        top: 0;
        z-index: 1;
        .comment {
            background-color: var(--shadow-background) !important;
            padding: 16px;
            &-title {
                font-weight: 500;
                margin-bottom: 16px;
            }
        }
    }
    &-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px;
    }
    &-title {
        display: flex;
        align-items: center;
        align-content: center;
        gap: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &.pagination {
            max-width: calc(100% - 160px);
        }
    }
    &-title-label {
        font-weight: 500;
        white-space: nowrap;
    }
    &-title-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: calc(100% - 16px);
    }
}
